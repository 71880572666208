import { useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useStatusModal } from "../context/StatusModalContext";
import { jwtDecode } from "jwt-decode";

class CustomError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { showStatusModal } = useStatusModal();
  const { t } = useTranslation();
  const siteLanguage = localStorage.getItem("language") || "en";

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const isTokenExpired = (token) => {
    try {
      const { exp } = jwtDecode(token);
      return Date.now() >= exp * 1000;
    } catch (err) {
      return true;
    }
  };

  const refreshTokenPromise = useRef(null);

  const refreshAccessToken = async () => {
    if (!refreshTokenPromise.current) {
      const ps10004000 = JSON.parse(localStorage.getItem("ps10004000"));
      const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
      const encodedToken = jwtDecode(ps10005000);

      refreshTokenPromise.current = fetch(`${baseUrl}/User/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: ps10004000,
          userId: encodedToken.uid,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new CustomError(t("error_session_expired"), 999);
          }
          return response.json();
        })
        .then((data) => {
          localStorage.setItem("ps10005000", JSON.stringify(data.token));
          localStorage.setItem("ps10004000", JSON.stringify(data.refreshToken));
          refreshTokenPromise.current = null;
          return data.token;
        })
        .catch((err) => {
          refreshTokenPromise.current = null;
          throw err;
        });
    }
    return refreshTokenPromise.current;
  };

  const apiCall = useCallback(
    async (endpoint, method, options = {}) => {
      setLoading(true);
      setError(null);

      try {
        let ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
        let token = ps10005000 ? ps10005000 : null;

        if (token && isTokenExpired(token)) {
          token = await refreshAccessToken();
        }

        const defaultHeaders = {
          "Content-Type": "application/json",
          "Accept-Language": siteLanguage,
          ...(token && { Authorization: `Bearer ${token}` }),
        };

        const response = await fetch(`${baseUrl}${endpoint}`, {
          method,
          ...options,
          headers: {
            ...defaultHeaders,
            ...options.headers,
          },
        });

        if (!response.ok) {
          let errorData = {};
          try {
            const text = await response.text();
            errorData = text ? JSON.parse(text) : {};
          } catch (error) {
            console.error(error);
          }

          const errorMessage =
            Number(errorData.status) === 500
              ? t("error_server")
              : errorData.detail ||
                (errorData.errors
                  ? Object.values(errorData.errors)[0][0]
                  : t("error_something_wrong"));

          throw new Error(errorMessage);
        }

        let data = {};
        try {
          const text = await response.text();

          try {
            // თუ გაიპარსა
            data = JSON.parse(text);

            //  თუ არა ერორიდან ტექსტს დავაბრუნებინებ
          } catch (error) {
            data = text;
          }
        } catch (error) {
          console.error(error);
        }

        return data;
      } catch (err) {
        setError(err.message);
        const isRefershError = err.code === 999;
        const redirectionUrl = isRefershError ? "/" : null;
        showStatusModal(err.message, redirectionUrl, true, isRefershError);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [baseUrl, showStatusModal],
  );

  const get = useCallback(
    (endpoint, options = {}) => apiCall(endpoint, "GET", options),
    [apiCall],
  );

  const post = useCallback(
    (endpoint, body, options = {}) =>
      apiCall(endpoint, "POST", { ...options, body: JSON.stringify(body) }),
    [apiCall],
  );

  const put = useCallback(
    (endpoint, body, options = {}) =>
      apiCall(endpoint, "PUT", { ...options, body: JSON.stringify(body) }),
    [apiCall],
  );

  const patch = useCallback(
    (endpoint, body, options = {}) =>
      apiCall(endpoint, "PATCH", { ...options, body: JSON.stringify(body) }),
    [apiCall],
  );

  const del = useCallback(
    (endpoint, options = {}) => apiCall(endpoint, "DELETE", options),
    [apiCall],
  );

  return { get, post, put, patch, del, loading, error };
};

export default useApi;
