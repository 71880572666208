import "./style.css";

const Button = ({ type, text, clickEvent, color }) => {
  return (
    <button
      className="button"
      type={type}
      onClick={clickEvent}
      style={{
        backgroundColor: color ? color : null,
      }}
    >
      {text}
    </button>
  );
};

export default Button;
