import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const StatusModalContext = createContext();

export const StatusModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isError: false,
    visible: false,
    clearStorage: false,
    message: "",
    redirectionUrl: null,
  });
  const navigate = useNavigate();
  const showStatusModal = (
    message,
    redirectionUrl = null,
    isError = false,
    clearStorage = false,
  ) => {
    setModalState({
      isError,
      clearStorage,
      visible: true,
      message,
      redirectionUrl,
    });
  };

  const closeStatusModal = () => {
    if (modalState.redirectionUrl) {
      navigate(modalState.redirectionUrl);
    }
    if (modalState.clearStorage) {
      localStorage.removeItem("ps10005000");
      localStorage.removeItem("ps10004000");
    }
    setModalState({
      isError: false,
      visible: false,
      message: "",
      redirectionUrl: null,
      clearStorage: false,
    });
  };

  return (
    <StatusModalContext.Provider
      value={{ modalState, showStatusModal, closeStatusModal }}
    >
      {children}
    </StatusModalContext.Provider>
  );
};

export const useStatusModal = () => useContext(StatusModalContext);
