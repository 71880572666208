import { createContext, useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";

const LoginDataContext = createContext({});

export const LoginDataProvider = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loginValidation, setLoginValidation] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { post, loading } = useApi();

  const FetchAuth = async () => {
    if (phoneNumber.trim() === "" || password === "") {
      setLoginValidation(t("error_login_field_empty"));
      return;
    }

    try {
      setLoginValidation("");
      const data = await post("/User/login", {
        phoneNumber: phoneNumber,
        password: password,
        rememberLogin: true,
      });
      if (!data.token) {
        setLoginValidation(t("error_login_field_incorrect"));
        return;
      }

      localStorage.setItem("ps10005000", JSON.stringify(data.token));
      localStorage.setItem("ps10004000", JSON.stringify(data.refreshToken));

      setPhoneNumber("");
      setPassword("");

      navigate("/sessions");
    } catch (error) {
      setLoginValidation(t("error_login_not_found"));
    }
  };

  return (
    <LoginDataContext.Provider
      value={{
        phoneNumber,
        password,
        changePhoneNumber: setPhoneNumber,
        changePassword: setPassword,
        FetchAuth,
        loginValidation,
        loginLoader: loading,
      }}
    >
      {children}
    </LoginDataContext.Provider>
  );
};

const useLogin = () => useContext(LoginDataContext);

export default useLogin;
