import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useApi from "../../hooks/useApi";

import { ReactComponent as Single } from "../../static/images/single_schedule.svg";
import { ReactComponent as Group } from "../../static/images/group_schedule.svg";
import { ReactComponent as Passed } from "../../static/images/passed_history.svg";
import { ReactComponent as Canceled } from "../../static/images/canceled_history.svg";

import "./style.scss";

const ProviderHistoryAttender = () => {
  const { get, loading } = useApi();
  const { t } = useTranslation();

  const location = useLocation();
  const { subscriberId, eventDate, description, groupStatus, weekday } =
    location.state || {};

  const navigate = useNavigate();

  const [subscriberData, setSubscriberData] = useState([]);

  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  const weekdays = [
    t("sunday"),
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
  ];

  function getMonthByIndex(index) {
    if (index >= 0 && index <= 11) {
      return months[index];
    }
  }

  const fetchSubscriberData = async () => {
    try {
      const data = await get(`/ApplicationEvent/${subscriberId}/Subscribers`);

      setSubscriberData(data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchSubscriberData();
  }, []);

  return (
    <div className="provider-history-attenders-container">
      <div className="description">
        <div className="description-icon">
          {groupStatus === true ? <Group /> : <Single />}
        </div>
        <span>{description}</span>
      </div>

      <div className="section-devider"></div>

      <div className="event-date">
        <h2>{weekdays[weekday] + " - " + new Date(eventDate).getDate()}</h2>
        <h2>
          {getMonthByIndex(new Date(eventDate).getMonth()) +
            " " +
            new Date(eventDate).getFullYear()}
        </h2>
      </div>

      <div className="section-devider"></div>
      <div className="attenders-card-container">
        <ul>
          {subscriberData &&
            subscriberData.map((item) => {
              return (
                <li key={item.businessAccount.id} className="details-card-li">
                  <div className="card-item">
                    <div className="card-item-content">
                      <div className="card-status">
                        {item.eventStatus === "Passed" ? (
                          <Passed />
                        ) : (
                          <Canceled />
                        )}
                      </div>
                      <div className="card-time">
                        <span>
                          {new Date(eventDate)
                            .getHours()
                            .toString()
                            .padStart(2, "0") +
                            ":" +
                            new Date(eventDate)
                              .getMinutes()
                              .toString()
                              .padStart(2, "0")}
                        </span>
                      </div>
                    </div>

                    <div className="card-name">
                      <span> {item.businessAccount.name}</span>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div
        className="provider-registraton-back"
        onClick={() => {
          navigate(-1);
        }}
      >
        <span>{t("go_previous")}</span>
      </div>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default ProviderHistoryAttender;
