import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as ProviderIcon } from "../../static/images/provider_icon.svg";
import { ReactComponent as ConsumerIcon } from "../../static/images/consumer_icon.svg";

import "./style.scss";

const UserRoleSelector = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="role-selector-container">
      <div className="role-title">
        <h1>{t("sign_up")}</h1>
      </div>
      <div className="role-selector-content">
        <div className="role-subtitle">
          <h3>{t("welcome")}</h3>
          <h3>{t("role_selector_page_header")}</h3>
        </div>

        <div className="user-section">
          <div
            className="user"
            onClick={() => {
              navigate("provider-registration");
            }}
          >
            <div className="role-image">
              <ProviderIcon />
            </div>
            <div className="role-text">
              <span>{t("role_selector_option_trainer")}</span>
            </div>
          </div>
          <div
            className="user"
            onClick={() => {
              navigate("consumer-registration");
            }}
          >
            <div className="role-image">
              <ConsumerIcon />
            </div>
            <div className="role-text">
              <span>{t("role_selector_option_player")}</span>
            </div>
          </div>
        </div>

        <div
          className="role-to-login"
          onClick={() => {
            navigate("/");
          }}
        >
          <span>{t("go_previous")}</span>
        </div>
      </div>
    </div>
  );
};

export default UserRoleSelector;
