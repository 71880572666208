import React from "react";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

import useApi from "../../hooks/useApi";
import { ReactComponent as GeoFlag } from "../../static/images/geo_flag.svg";
import { ReactComponent as EnglishFlag } from "../../static/images/english_flag.svg";
import { ReactComponent as SpainFlag } from "../../static/images/spain_flag.svg";
import { ReactComponent as ItalyFlag } from "../../static/images/italy_flag.svg";
import { ReactComponent as UkraineFlag } from "../../static/images/ukraine_flag.svg";
import { generateUniqueId } from "../../store/GlobalVarriables";

import "./style.scss";

const languages = [
  { lang: "ka", flag: <GeoFlag />, show: true, id: generateUniqueId() },
  { lang: "en", flag: <EnglishFlag />, show: true, id: generateUniqueId() },
  { lang: "ukr", flag: <UkraineFlag />, show: false, id: generateUniqueId() },
  { lang: "esp", flag: <SpainFlag />, show: false, id: generateUniqueId() },
  { lang: "ita", flag: <ItalyFlag />, show: false, id: generateUniqueId() },
];

const LangSwitcher = () => {
  const { patch } = useApi();
  const { i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("language") || "en";
  const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
  const decodedToken = ps10005000 ? jwtDecode(ps10005000) : null;

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);

    if (ps10005000 && decodedToken) {
      patch("/BusinessAccount", {
        id: decodedToken.buid,
        language: lang === "en" ? "ENG" : "GEO",
      });
    }
  };

  const finalLangs = languages.filter(
    (item) => item.show && item.lang !== savedLanguage,
  );

  return (
    <div className="lang-switcher">
      {finalLangs.map((item) => {
        return (
          <button key={item.id} onClick={() => handleLanguageChange(item.lang)}>
            {item.flag}
          </button>
        );
      })}
    </div>
  );
};

export default LangSwitcher;
