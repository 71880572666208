import { useCallback, useState } from "react";

import Header from "../Components/Header";
import Router from "../Router/index";
import Footer from "../Components/Footer";
import FeatureTooltip from "../Components/FeatureTooltip/FeatureTooltip";

import "./style.css";

const Layout = () => {
  const [active, setActive] = useState("passive");

  const sandwichToggle = useCallback(() => {
    return setActive((postState) =>
      postState === "active" ? "passive" : "active",
    );
  }, [active]);

  return (
    <div className={`layout`}>
      <div className={`left ${active}`}></div>
      <div className={`right ${active}`} onClick={sandwichToggle}></div>
      <FeatureTooltip menuState={active} togleMenu={sandwichToggle} />
      <div className="header-layout">
        <Header active={active} sandwichToggle={sandwichToggle} />
      </div>
      <div>
        <Router />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
