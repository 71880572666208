import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import "./style.scss";

const ModalWarning = ({
  warningText,
  modalSubText,
  changeModal,
  modalValue,
  Image,
  buttonText,
  handleButton,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // modalValue - "modal-warning"

  return (
    <div className={`warning-container ${modalValue}`}>
      <div className="warning-content">
        <div className="warning-icon">
          <Image />
        </div>
        <div className="warning-text">
          <h3>{warningText}</h3>
        </div>
        <div className="warning-sub-text">
          <span>{modalSubText}</span>
        </div>
        <div className="warning-button">
          <div>
            <button
              onClick={() => {
                changeModal("");

                if (buttonText === t("close")) {
                  return;
                }
                if (buttonText === t("go_invitations")) {
                  navigate("/invitation");
                  return;
                }
                if (buttonText === t("go_main_page")) {
                  navigate("/sessions");
                  return;
                }
                if (buttonText === t("go_schedule_details")) {
                  handleButton();
                  return;
                }
                if (buttonText === t("go_login_page")) {
                  navigate("/");
                  return;
                } else {
                  handleButton();
                }
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWarning;
