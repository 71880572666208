export const customersArray = [
  {
    day: 1,
    icon: "tennis",
    title: "Elene's Tennis",
    clock: "14:30",
  },
  {
    day: 2,
    icon: "english",
    title: "Elene's English Lesson",
    clock: "16:00",
  },
  {
    day: 3,
    icon: "football",
    title: "Demetre's Football",
    clock: "16:00",
  },
];

const schedule = [
  {
    availableSeats: 1,
    cancellationGraceHours: 20,
    creationDateTime: "2024-04-06T18:56:11.647271",
    description: "alex desc",
    duration: 10,
    effectiveFrom: "2024-04-06T14:52:58.392",
    groupSchedule: true,
    id: 1,
    maxMembers: 2,
    price: 50,
    days: [
      {
        dayOfWeek: "Sunday",
        id: 1,
        timeOnly: "10:20:00",
      },
      {
        dayOfWeek: "Monday",
        id: 2,
        timeOnly: "11:30:00",
      },
      {
        dayOfWeek: "Tuesday",
        id: 3,
        timeOnly: "10:20:00",
      },
    ],
  },
  {
    availableSeats: 10,
    cancellationGraceHours: 10,
    creationDateTime: "2024-04-08T17:34:40.839521",
    description: "2-nd description",
    duration: 10,
    effectiveFrom: "2024-04-08T13:34:15.329",
    groupSchedule: true,
    id: 4,
    maxMembers: 10,
    price: 60,

    days: [
      {
        dayOfWeek: "Sunday",
        id: 7,
        timeOnly: "14:00:00",
      },
      {
        dayOfWeek: "wednesday",
        id: 8,
        timeOnly: "14:00:00",
      },
    ],
  },
];

const merging = () => {
  const mergedSchedule = {};

  // Iterate through the schedule array
  schedule.forEach((entry) => {
    // Iterate through the days of each entry
    entry.days.forEach((day) => {
      // Check if the dayOfWeek already exists in the merged schedule
      if (day.dayOfWeek.toLowerCase() in mergedSchedule) {
        // If exists, push the current entry to the array of that day
        mergedSchedule[day.dayOfWeek.toLowerCase()].push(entry);
      } else {
        // If not, create a new array with the current entry
        mergedSchedule[day.dayOfWeek.toLowerCase()] = [entry];
      }
    });
  });

  console.log(mergedSchedule);
};
