import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApi from "../../hooks/useApi";
import { useModalStore } from "../../store/GlobalVarriables";

import ScheduleInviteInput from "../../Components/ScheduleInviteInput/ScheduleInviteInput";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import ModalWarning from "../../Components/ModalWarning/ModalWarning";
import DotsMenu from "../../Components/DotsMenu";
import Weekdays from "../../Components/Weekdays/Weekdays";

import { ReactComponent as PayerBadge } from "../../static/images/payer_badge.svg";
import { ReactComponent as DuePayment } from "../../static/images/due_payment.svg";
import { ReactComponent as Tick } from "../../static/images/tick_sign.svg";

import "./style.scss";

const ScheduleDetails = () => {
  const { get, post, del, put, patch, loading } = useApi();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const invRef = useRef(null);
  const subRef = useRef(null);

  const { scheduleID } = useParams();

  const [scheduleDetail, setScheduleDetail] = useState([]);
  const [invitationData, setInvitationData] = useState([]);
  const [subDetails, setSubDetails] = useState([]);

  const [invitationInputToggle, setInvitationInputToggle] = useModalStore("");

  const [confirmModalToggle, setConfirmModalToggle] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalSubText, setModalSubText] = useState("");
  const [modalValue, setModalValue] = useState(0); // for detect what action is needed

  const [warningModalToggle, setWarningModalToggle] = useState("");
  const [warningModalText, setWarningModalText] = useState("");
  const [warningButtonText, setWarningButtonText] = useState("");

  const [price, setPrice] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  const [priceLabel, setPriceLabel] = useState(t("input_label_price"));
  const [mobileLabel, setMobileLabel] = useState(
    t("input_label_customer_mobile"),
  );
  const [emailLabel, setEmailLabel] = useState(t("input_label_customer_email"));

  const [detailMenuToggle, setDetailMenuToggle] = useState(false);

  const [scheduleId, setScheduleId] = useState("");
  const [groupPayerId, setGroupPayerId] = useState("");
  const [currentPayer, setCurrentPayer] = useState([]);
  const [payerIdentifier, setPayerIdentifier] = useState([]);

  const menuItems = [
    {
      text: t("invite_customer"),
      disabled: scheduleDetail.availableSeats <= 0,
      onClick: () => {
        handleValidations();
      },
    },
    {
      text: t("cancel_schedule"),
      onClick: () => handleMenuButton("cancel schedule"),
    },
    {
      text: t("edit_schedule"),
      onClick: () => {
        navigate(`/schedule/edit/${scheduleID}`);
      },
    },
    {
      text: t("finance"),
      disabled: true,
    },
    {
      text: t("training_history"),
      onClick: () => {
        navigate("/history/byschedule");
      },
    },
  ];

  const fetchDetails = async () => {
    try {
      const data = await get(`/Schedule/${scheduleID}`);
      setPrice(data.price);
      setScheduleDetail(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInvitation = async () => {
    try {
      const data = await get(`/Schedule/${scheduleID}/Invitations`);
      if (data) {
        const initializedInvitationData = data.map((item) => ({
          invitedNumber: item.invitedPhoneNumber,
          invitedEmail: item.invitedEmailAddress,
          status: item.status,
          id: item.id,
          showMenu: false,
        }));

        setInvitationData(initializedInvitationData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSub = async () => {
    try {
      const data = await get(`/Schedule/${scheduleID}/Subscriptions`);

      if (data) {
        const initializedSubscriptionData = data.map((item) => ({
          businessAccountName: item.businessAccount.name,
          overduePayment: item.overduePayment,
          id: item.id,
          isGroupPayer: item.isGroupPayer,
          showMenu: false,
        }));
        setSubDetails(initializedSubscriptionData);
        setCurrentPayer(data.filter((item) => item.isGroupPayer === true));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGroupPayer = async () => {
    if (currentPayer.length) {
      try {
        const data = await patch(`/Subscription`, {
          id: currentPayer[0].id,
          isGroupPayer: false,
        });
      } catch (error) {}
    }

    try {
      const data = await patch(`/Subscription`, {
        id: groupPayerId,
        isGroupPayer: true,
        // description: "string",
      });

      await fetchSub();
    } catch (error) {
      console.error(error);
    }
  };

  const getDataId = (id) => {
    setScheduleId(id);
  };

  const postInvitation = async () => {
    if (modalSubText === "") {
      setInvitationInputToggle("");
      setEmail("");
      setMobile("");
    } else if (modalValue == 1) {
      try {
        const data = await del(`/Schedule/${scheduleDetail.id}/Cancel`);

        setWarningModalToggle("modal-warning");
        setWarningModalText(t("cancel_success"));
        setWarningButtonText(t("go_schedule_details"));
        navigate("/sessions");
      } catch (error) {
        console.error(error);
      }
    } else if (modalValue == 2) {
      try {
        const data = await put(`/Invitation`, {
          id: scheduleId,
          operation: "Cancel",
        });

        setWarningModalToggle("modal-warning");
        setWarningModalText(t("cancel_success"));
        setWarningButtonText(t("go_schedule_details"));
      } catch (error) {
        console.error(error);
      }
    } else if (modalValue == 3) {
      try {
        const data = await del(`/Subscription/${scheduleId}/Cancel`);
        setWarningModalToggle("modal-warning");
        setWarningModalText(t("cancel_success"));
        setWarningButtonText(t("go_schedule_details"));
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const data = await post(`/Invitation`, {
          scheduleId: scheduleDetail.id,
          customerPhoneNumber: mobile || null,
          customerEmailAddress: email || null,
          price: price,
        });

        setWarningModalToggle("modal-warning");
        setWarningModalText(t("invitation_sent_success"));
        setWarningButtonText(t("go_schedule_details"));
      } catch (error) {
        console.error("Error fetching invite:", error);
      }
    }
  };

  const handleValidations = () => {
    /// disappear invitation menu
    setInvitationData(
      invitationData.map((item, i) => ({
        ...item,
        showMenu: false,
      })),
    );

    /// disappear subscription menu
    setSubDetails(
      subDetails.map((item, i) => ({
        ...item,
        showMenu: false,
      })),
    );

    if (scheduleDetail.availableSeats > 0) {
      setInvitationInputToggle("invite");
      setDetailMenuToggle(false);
    }

    if (invitationInputToggle === "invite") {
      if (price.length === 0) {
        setPriceLabel(t("error_price_required"));
        return;
      }
      if (mobile === "" && email === "") {
        setMobileLabel(t("error_email_or_mobile_required"));
        setEmailLabel(t("error_email_or_mobile_required"));
        return;
      }
      if (
        (emailLabel.includes("!") && email.length > 0) ||
        (mobileLabel.includes("!") && mobile.length > 0)
      ) {
        return;
      }
      setMobileLabel(t("input_label_customer_mobile"));
      setEmailLabel(t("input_label_customer_email"));

      setConfirmModalToggle("modal-confirm");
      setModalText(t("create_invitation_confirm_text"));
      setModalSubText(t("create_invitation_confirmation_text"));
    }
  };

  const changeLabelHandler = (lable, value) => {
    let numberValidation = /^[1-9]\d*(\.\d+)?$|^0(\.\d+)?$/;
    let emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (lable === "price") {
      if (value.length === 0) {
        setPriceLabel(t("error_price_required"));
      } else if (!numberValidation.test(value)) {
        setPriceLabel(t("error_is_not_number"));
      } else {
        setPriceLabel(t("input_label_price"));
      }
    }

    if (lable === "customerNum") {
      if (value.length === 0) {
        setMobileLabel(t("error_cutomer_mobile_required"));
      } else if (!numberValidation.test(value)) {
        setMobileLabel(t("error_is_not_number"));
      } else if (value.length !== 9) {
        setMobileLabel(t("error_mobile_length"));
      } else {
        setMobileLabel(t("input_label_customer_mobile"));
      }
    }

    if (lable === "email") {
      if (value.length === 0) {
        setEmailLabel(t("error_email_required"));
      } else if (!emailValidation.test(String(value).toLowerCase())) {
        setEmailLabel(t("error_email_format"));
      } else {
        setEmailLabel(t("input_label_customer_email"));
      }
    }
  };

  const handleCancel = (buttonText) => {
    if (buttonText === "back") {
      navigate("/schedules");
    } else {
      if (mobile === "" && email === "") {
        setInvitationInputToggle("");
      } else {
        setConfirmModalToggle("modal-confirm");
        setModalText(t("page_leave_warning"));
        setModalSubText("");
      }
    }
  };

  const handleMenuButton = async (identity, payerItem) => {
    setDetailMenuToggle(false);

    if (identity === "cancel schedule") {
      setConfirmModalToggle("modal-confirm");
      setModalText(t("cancel_schedule_confirmation_modal_text"));
      setModalSubText(t("cancel_schedule_confirmation_modal_subText"));
      setModalValue(1);
    } else if (identity === "cancel invitation") {
      setConfirmModalToggle("modal-confirm");
      setModalText(t("cancel_invitation_confirmation_modal_text"));
      setModalSubText(t("cancel_invitation_confirmation_modal_subText"));
      setModalValue(2);
      /// disappear invitation menu
      setInvitationData(
        invitationData.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );
    } else if (identity === "cancel subscription") {
      setConfirmModalToggle("modal-confirm");
      setModalText(t("cancel_subscription_confirmation_modal_text"));
      setModalSubText(t("cancel_subscription_confirmation_modal_subText"));
      setModalValue(3);
      /// disappear subscription menu
      setSubDetails(
        subDetails.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );
    } else if ("select as group payer") {
      if (payerIdentifier.isGroupPayer === true) {
        return;
      }

      setSubDetails(
        subDetails.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );

      setConfirmModalToggle("modal-confirm");
      setModalText(
        t("add_into_group_confirmation_modal_text", {
          name: payerItem.businessAccountName,
          scheduleName: scheduleDetail.description.trim(""),
        }),
      );
      setModalSubText(t("add_into_group_confirmation_modal_subText"));
      setModalValue(4);
      setGroupPayerId(payerItem.id);
    }
  };

  const toggleMenu = (identity, index) => {
    if (identity === "invitation") {
      setInvitationData(
        invitationData.map((item, i) =>
          i === index
            ? { ...item, showMenu: !item.showMenu }
            : { ...item, showMenu: false },
        ),
      );

      /// disappear subscription menu
      setSubDetails(
        subDetails.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );
    } else {
      setSubDetails(
        subDetails.map((item, i) =>
          i === index
            ? { ...item, showMenu: !item.showMenu }
            : { ...item, showMenu: false },
        ),
      );
      /// disappear invitation menu
      setInvitationData(
        invitationData.map((item, i) => ({
          ...item,
          showMenu: false,
        })),
      );
    }
  };

  const handleToggleDetailsMenu = () => {
    setDetailMenuToggle((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (invRef.current && !invRef.current.contains(event.target)) {
      setInvitationData(
        invitationData.map((item) => ({
          ...item,
          showMenu: false,
        })),
      );
    }
    if (subRef.current && !subRef.current.contains(event.target)) {
      setSubDetails(
        subDetails.map((item) => ({
          ...item,
          showMenu: false,
        })),
      );
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [invitationData]);

  const warningButton = () => {
    setInvitationInputToggle("");
    setEmail("");
    setMobile("");
    fetchDetails();
    fetchSub();
    fetchInvitation();
  };

  useEffect(() => {
    try {
      fetchDetails();
      fetchInvitation();
      fetchSub();
    } catch (err) {
      console.error("Error:", err);
    }
  }, []);

  return (
    <div className={`detail-page`}>
      <div className="details-container">
        <div className="details-description">
          <span>{scheduleDetail.description}</span>
          <div className="deatils-menu">
            <DotsMenu
              menuItems={menuItems}
              isMenuOpen={detailMenuToggle}
              toggleMenu={() => handleToggleDetailsMenu()}
            />
          </div>
        </div>

        {scheduleDetail?.scheduleDays && (
          <Weekdays weekdaysData={scheduleDetail.scheduleDays} />
        )}

        <div className="financial-details">
          <h4>{t("financial_section_header")}</h4>

          <ul>
            {scheduleDetail.groupSchedule ? (
              <li>
                {scheduleDetail?.financialDetails?.payerOption === "Individual"
                  ? t("details_paymentType_individual")
                  : t("details_paymentType_onePay")}
              </li>
            ) : (
              <li>{t("details_individual_schedule")}</li>
            )}
            <li>
              {t("details_price", {
                price: scheduleDetail?.price,
                period:
                  scheduleDetail?.financialDetails?.pricingType === "Monthly"
                    ? `/ ${t("month")}`
                    : `/ ${t("day")}`,
              })}
            </li>
            <li>
              {t(
                scheduleDetail?.financialDetails?.billingPeriod ===
                  "End_Of_The_Month"
                  ? "details_billingType_end_of_month"
                  : scheduleDetail?.financialDetails?.billingPeriod ===
                      "Beginning_of_the_Month"
                    ? "details_billingType_beginning_of_month"
                    : "details_billingType_based_on_events",
              )}
            </li>
            <li>
              {scheduleDetail?.financialDetails?.paymentType === "PrePaid"
                ? t(
                    scheduleDetail.groupSchedule
                      ? "details_paymentType_prePaid_group"
                      : "details_paymentType_prePaid_individual",
                    {
                      count:
                        scheduleDetail?.financialDetails?.prepaymentEventCount,
                    },
                  )
                : t(
                    scheduleDetail.groupSchedule
                      ? "details_paymentType_postPaid_group"
                      : "details_paymentType_postPaid_individual",
                  )}
            </li>
          </ul>
        </div>
        {invitationData.length > 0 ? (
          <div className={`sub-details-container ${invitationInputToggle}`}>
            {invitationData.map((item, index) => {
              return (
                <div
                  className="single-sub"
                  key={item.id}
                  onClick={() => {
                    getDataId(item.id);
                  }}
                >
                  <div className="single-sub-name">
                    {item.invitedNumber ? (
                      <h3>{item.invitedNumber}</h3>
                    ) : (
                      <h3>{item.invitedEmail}</h3>
                    )}
                  </div>
                  <div className="single-sub-icons">
                    <div className="single-sub-status">
                      <span>{item.status}</span>
                    </div>
                    <div className="details-settings">
                      <DotsMenu
                        menuItems={[
                          {
                            text: t("cancel_invitation"),
                            onClick: () =>
                              handleMenuButton("cancel invitation"),
                          },
                        ]}
                        isMenuOpen={item.showMenu}
                        toggleMenu={() => toggleMenu("invitation", index)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          subDetails.length === 0 && (
            <div className={`empty-data ${invitationInputToggle}`}>
              {t("details_dont_have_subscription")}
            </div>
          )
        )}

        {subDetails.length > 0 ? (
          <div className={`sub-details-container ${invitationInputToggle}`}>
            {subDetails.map((item, index) => {
              return (
                <div
                  className="single-sub"
                  key={item.id}
                  onClick={() => {
                    getDataId(item.id);
                    setPayerIdentifier(item);
                  }}
                >
                  <div className="single-sub-name">
                    <h3>{item.businessAccountName}</h3>
                  </div>
                  <div className="single-sub-icons">
                    {scheduleDetail?.financialDetails?.payerOption ===
                    "Group" ? (
                      <>
                        {item.isGroupPayer === true ? (
                          <div className="group-payer">
                            <PayerBadge />
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {item.overduePayment === true ? (
                      <div className="group-payer">
                        <DuePayment />
                      </div>
                    ) : null}

                    {scheduleDetail?.financialDetails?.payerOption ===
                    "Group" ? (
                      <div className="details-settings">
                        <DotsMenu
                          menuItems={[
                            {
                              text: t("cancel_invitation"),
                              onClick: () =>
                                handleMenuButton("cancel subscription"),
                            },

                            {
                              text: t("select_as_group_payer"),
                              disabled:
                                payerIdentifier.isGroupPayer === true
                                  ? true
                                  : false,
                              onClick: () =>
                                handleMenuButton("select as group payer", item),
                            },
                          ]}
                          isMenuOpen={item.showMenu}
                          toggleMenu={() => toggleMenu("subscription", index)}
                        />
                      </div>
                    ) : (
                      <div className="details-settings">
                        <DotsMenu
                          menuItems={[
                            {
                              text: t("cancel_invitation"),
                              onClick: () =>
                                handleMenuButton("cancel subscription"),
                            },
                          ]}
                          isMenuOpen={item.showMenu}
                          toggleMenu={() => toggleMenu("subscription", index)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        <div className={`details-inputs-container ${invitationInputToggle}`}>
          <ScheduleInviteInput
            name={"price"}
            value={price}
            changeValue={setPrice}
            label={priceLabel}
            changeLableValue={changeLabelHandler}
          />
          <ScheduleInviteInput
            name={"customerNum"}
            label={mobileLabel}
            changeValue={setMobile}
            autoComplete={"tel"}
            value={mobile}
            changeLableValue={changeLabelHandler}
          />
          <div
            style={{
              textAlign: "center",
              paddingBottom: "25px",
              color: "#A1A9BC",
            }}
          >
            <span>{t("or")}</span>
          </div>
          <ScheduleInviteInput
            name={"email"}
            label={emailLabel}
            changeValue={setEmail}
            autoComplete={"email"}
            value={email}
            changeLableValue={changeLabelHandler}
          />
        </div>

        <ModalConfirmation
          modalText={modalText}
          modalSubText={modalSubText}
          changeModal={setConfirmModalToggle}
          modalValue={confirmModalToggle}
          confirm={modalValue == 4 ? handleGroupPayer : postInvitation}
        />

        <ModalWarning
          warningText={warningModalText}
          buttonText={warningButtonText}
          changeModal={setWarningModalToggle}
          modalValue={warningModalToggle}
          Image={Tick}
          handleButton={warningButton}
        />
      </div>

      <div className="details-invite-buttons">
        <button
          id="details-invite"
          onClick={() => {
            handleValidations();
          }}
          disabled={scheduleDetail.availableSeats > 0 ? false : true}
          style={{
            opacity: scheduleDetail.availableSeats > 0 ? 1 : 0.5,
          }}
        >
          {t("invite_customer")}
        </button>
        <button
          id={`details-back`}
          style={{
            backgroundColor:
              invitationInputToggle === "invite" ? "#c11313" : "#d9d9d9",
            color: invitationInputToggle === "invite" ? "#fff" : "black",
          }}
          onClick={(e) => {
            handleCancel(
              invitationInputToggle === "invite" ? "cancel" : "back",
            );
          }}
        >
          {t(invitationInputToggle === "invite" ? "cancel" : "back")}
        </button>
      </div>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default ScheduleDetails;
