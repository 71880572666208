import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ReactComponent as CellsIcon } from "../../static/images/cells.svg";
import { ReactComponent as CalendarIcon } from "../../static/images/calendar.svg";
import { ReactComponent as ClockIcon } from "../../static/images/sand_clock.svg";

import "./style.scss";

const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  const getUserRole = () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
    if (ps10005000) {
      const encodedToken = jwtDecode(ps10005000);
      return encodedToken.roles;
    }
  };

  return (
    <div className="footer-container">
      {pathname === "/invitation" ? null : (
        <nav className="nav-container">
          {(pathname === "/sessions" && getUserRole() === "Provider") ||
          (pathname === "/schedules" && getUserRole() === "Provider") ? (
            <div className="add-schedule">
              <button
                onClick={() => {
                  navigate("/invite");
                }}
              >
                {t("create_new_chedule")}
              </button>
            </div>
          ) : null}

          {(pathname === "/sessions" || pathname === "/history") &&
            getUserRole() === "Consumer" && (
              <div className="links-wrapper">
                <NavLink className="nav-link disabled" to="#">
                  <CellsIcon className="icon" />
                  <span>{t("clubs_and_tariners")}</span>
                </NavLink>
                <NavLink to="/sessions" className="nav-link sessions">
                  <CalendarIcon className="icon" />
                  <span>{t("sessions")}</span>
                </NavLink>
                <NavLink className="nav-link history" to="/history">
                  <ClockIcon className="icon" />
                  <span>{t("history")}</span>
                </NavLink>
              </div>
            )}
        </nav>
      )}
      <div className="skedy-rights">
        <p>{t("skedy_rights", { year: currentYear })}</p>
      </div>
    </div>
  );
};

export default Footer;
