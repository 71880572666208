export const TOOLTIP_STORAGE_KEY_PROVIDER = "sked-85-95-96";
export const TOOLTIP_STORAGE_KEY_CONSUMER = "sked-85-109";

export const featuresProvider = [
  {
    name: "New Features are available!",
    steps: [
      {
        content: [
          "Schedule Editing",
          "Training History",
          "Creating new schedule from Schedule page",
        ],
        isCenter: true,
      },
    ],
  },
  {
    name: "Schedule Editing:",
    steps: [
      {
        content: "now you can modify existing schedules.",
        position: { top: 20, left: 60 },
        arrowPosition: "left",
      },
      {
        content: "you have to navigate to Schedule page!",
        position: { top: 140, left: 100 },
        arrowPosition: "left",
        redirectionUrl: "/schedules",
        isToggleMenu: true,
      },
      {
        content: "to modify existing schedule click action menu.",
        position: { top: 90, right: 45 },
        arrowPosition: "right",
        redirectionUrl: "/schedules?showFeatureTooltip=true",
        isToggleMenu: true,
      },
      {
        content: "choose 'Edit Schedule' option from the list!",
        position: { top: 170, right: 205 },
        arrowPosition: "right",
        redirectionUrl: "/schedules",
      },
    ],
  },
  {
    name: "Traning History:",
    steps: [
      {
        content:
          "you can see how many sessions has been occured for each schedule!",
        position: { top: 20, left: 60 },
        arrowPosition: "left",
      },
      {
        content: "you have to navigate to Training History page!",
        position: { top: 103, left: 140 },
        arrowPosition: "left",
        redirectionUrl: "/schedules",
        isToggleMenu: true,
      },
      {
        content: "or navigate from Schedule actions:",
        position: { top: 90, right: 45 },
        arrowPosition: "right",
        redirectionUrl: "/schedules?showFeatureTooltip=true",
        isToggleMenu: true,
      },
      {
        content: "select 'Training History' from schedule action options!",
        position: { top: 310, right: 205 },
        arrowPosition: "right",
        redirectionUrl: "/schedules",
      },
    ],
  },
  {
    name: "Add Schedule:",
    isCenter: true,
    steps: [
      {
        content: "now you can add new schedule from Schedule Page!",
        isCenter: true,
        isToggleMenu: true,
      },
      {
        content:
          "Click 'Create New Schedule' botton below to create new record.",
        position: { bottom: 100, left: 60 },
        arrowPosition: "bottom",
        redirectionUrl: "/sessions",
        isToggleMenu: false,
      },
    ],
  },
];

export const featuresConsumer = [

  {
    name: "New Features are available!",
    steps: [
      {
        content: [
          "Training History",
          "Language Switcher",
        ],
        isCenter: true,
      },
    ],
  },

  {
    name: "Training History:",
    steps: [
      {
        content: "now you can see how many sessions you completed for month.",
        position: { bottom: 125, left: 30 },
        arrowPosition: "bottom",
        redirectionUrl: "/history",
      },
      {
        content: "you can select month!",
        position: { top: 150, left: 30 },
        arrowPosition: "top",
        redirectionUrl: "/history",
      },
      {
        content: "You will see how many sessions has been passed or canceled",
        position: { top: 370, left: 30 },
        arrowPosition: "top",
        redirectionUrl: "/history?showFeatureTooltip=true",
      },
    ],
  },

  {
    name: "New language is available:",
    isCenter: true,
    steps: [
      {
        content: "you can change language from burger menu!",
        position: { top: 20, left: 60 },
        arrowPosition: "left",
        isToggleMenu: true,
       
      },
      {
        content:
          "Click flag to change language.",
        position: { top: 330, left: 10 },
        redirectionUrl: "/sessions?showFeatureTooltip=true",
        isToggleMenu: true,
      },
    ],
  },
];