import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useApi from "../../hooks/useApi";

import { monthNames } from "../../store/GlobalVarriables";
import { useStatusModal } from "../../context/StatusModalContext";

import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import Weekdays from "../../Components/Weekdays/Weekdays";

import "./style.scss";

const Reschedule = () => {
  const { get, post, patch } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { showStatusModal } = useStatusModal();
  const { scheduleID } = useParams();
  const { originalEventDate, dayId, eventId } = location.state || {};

  const [scheduleDetail, setScheduleDetail] = useState([]);

  const [confirmModalToggle, setConfirmModalToggle] = useState("");

  const [formState, setFormState] = useState({
    newDate: { hasError: false, label: "New date", value: "" },
    newTime: { hasError: false, label: "New time", value: "" },
  });

  const isRescheduleDisabled =
    formState.newDate.hasError ||
    formState.newTime.hasError ||
    !formState.newDate.value ||
    !formState.newTime.value;

  const fetchDetails = async () => {
    try {
      const data = await get(`/Schedule/${scheduleID}`);
      setScheduleDetail(data);
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = monthNames[date.getMonth()];

    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}`;

    return formattedDate;
  };

  useEffect(() => {
    try {
      fetchDetails();
    } catch (err) {
      console.error("Error:", err);
    }
  }, []);

  const removeTime = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const newDate = new Date(value);
    const currentDate = new Date();
    if (removeTime(newDate) >= removeTime(currentDate)) {
      if (removeTime(newDate) > removeTime(currentDate)) {
        setFormState((prevState) => ({
          [name]: {
            hasError: false,
            label: t("input_label_newDate"),
            value,
          },
          newTime: {
            hasError: false,
            label: t("input_label_newTime"),
            value: prevState.newTime.value,
          },
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [name]: {
            hasError: false,
            label: t("input_label_newDate"),
            value,
          },
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: {
          hasError: true,
          label: t("error_date_less_then_today"),
          value: "",
        },
      }));
    }
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    const newDate = new Date(formState.newDate.value);
    const currentDate = new Date();
    const isDateEqual =
      removeTime(newDate).toDateString() ===
      removeTime(currentDate).toDateString();
    const [pickerHours, pickerMinutes] = value.split(":").map(Number);
    const pickerTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      pickerHours,
      pickerMinutes,
    );

    const isPickerTimeGreater = pickerTime > currentDate;
    if (!isDateEqual || (isDateEqual && isPickerTimeGreater)) {
      setFormState((prevState) => ({
        ...prevState,
        [name]: {
          hasError: false,
          label: t("input_label_newDate"),
          value,
        },
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: {
          hasError: true,
          label: t("error_date_less_then_today"),
          value,
        },
      }));
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const hanleBlur = (e) => {
    if (!e.target.value) {
      const newLabel =
        e.target.name === "newDate"
          ? t("input_label_select_date")
          : t("input_label_select_time");
      setFormState((prevState) => ({
        ...prevState,
        [e.target.name]: { hasError: true, label: newLabel },
      }));
    }
  };

  const reschedule = () => {
    const originalDate = new Date(originalEventDate);
    originalDate.setHours(originalDate.getHours() + 4);
    const newEventDate = new Date(
      `${formState.newDate.value} ${formState.newTime.value}`,
    );
    const successMessage = t("reschedule_success", {
      date: formatDate(newEventDate),
    });
    newEventDate.setHours(newEventDate.getHours() + 4);

    const method = !!eventId ? patch : post;
    const dataToPass = eventId
      ? {
          id: eventId,
          eventDate: newEventDate.toISOString(),
          eventStatus: "Rescheduled",
        }
      : {
          scheduleDayId: dayId,
          eventTime: newEventDate.toISOString(),
          originalEventTime: originalDate.toISOString(),
          scheduleId: Number(scheduleID),
          eventStatus: "Rescheduled",
        };
    const response = method("/ApplicationEvent", dataToPass);

    if (response) {
      showStatusModal(successMessage, "/sessions");
    }
  };

  const [detailsDate, detailsTime] = formatDate(originalEventDate)
    .split(" ")
    .reduce(
      (acc, part) => {
        if (isNaN(part) && part.includes(":")) {
          acc[1] += part;
        } else {
          acc[0] += part + " ";
        }
        return acc;
      },
      ["", ""],
    );

  return (
    <div>
      <div className="rescedule-container">
        <div className="reschedule-description">
          <span>{scheduleDetail.description}</span>
        </div>

        {scheduleDetail?.scheduleDays && (
          <Weekdays weekdaysData={scheduleDetail.scheduleDays} />
        )}

        <div className="details">
          <p>{t("reschedule_details_info_text")}</p>
          <span>
            {t("reschedule_details_date")}
            <b> {detailsDate}</b>
          </span>
          <span>
            {t("reschedule_details_time")}
            <b> {detailsTime}</b>
          </span>
        </div>

        <div className="inputs-wrapper">
          <div className="input-date">
            <input
              id="date"
              name="newDate"
              type="date"
              onChange={handleDateChange}
              onClick={(e) => e.target.showPicker()}
              onBlur={hanleBlur}
            />
            <label
              htmlFor="date"
              style={
                formState.newDate.hasError
                  ? { color: "red" }
                  : { color: "rgb(143, 142, 142)" }
              }
            >
              {formState.newDate.label}
            </label>
          </div>

          <div className="input-date">
            <input
              id="time"
              name="newTime"
              type="time"
              onChange={handleTimeChange}
              onClick={(e) => e.target.showPicker()}
              onBlur={hanleBlur}
              disabled={!formState.newDate.value}
            />
            <label
              htmlFor="time"
              style={
                formState.newTime.hasError
                  ? { color: "red" }
                  : { color: "rgb(143, 142, 142)" }
              }
            >
              {formState.newTime.label}
            </label>
          </div>
        </div>

        <ModalConfirmation
          modalText={t("reschedule_confirm_text")}
          modalSubText={t("reschedule_confirm_subtext")}
          changeModal={setConfirmModalToggle}
          modalValue={confirmModalToggle}
          confirm={() => reschedule()}
        />
      </div>

      <div className="reschedule-buttons">
        <button
          className="rescheduleBtn"
          onClick={() => {
            setConfirmModalToggle("modal-confirm");
          }}
          disabled={isRescheduleDisabled}
          style={{
            opacity: isRescheduleDisabled ? 0.5 : 1,
          }}
        >
          {t("reschedule")}
        </button>
        <button
          className="cancelBtn"
          style={{
            backgroundColor: "red",
            color: "#fff",
          }}
          onClick={handleGoBack}
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};

export default Reschedule;
