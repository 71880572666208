import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

import { format } from "date-fns";
import { endDateIndex } from "../../store/GlobalVarriables";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import ScrollableItems from "../../Components/ScrollableItems";
import DotsMenu from "../../Components/DotsMenu";
import { monthNames } from "../../store/GlobalVarriables";
import useApi from "../../hooks/useApi";
import { useStatusModal } from "../../context/StatusModalContext";
import { getNext30Days } from "../../helpers/days";

import { ReactComponent as Single } from "../../static/images/single_schedule.svg";
import { ReactComponent as Group } from "../../static/images/group_schedule.svg";

import "./style.scss";

const ScheduleProvider = () => {
  const { get, patch, post, loading } = useApi();
  const { t } = useTranslation();

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scheduleEventData, setScheduleEventData] = useState();
  const [confirmModalToggle, setConfirmModalToggle] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedEventDate, setSelectedEventDate] = useState(null);
  const [scrollByCode, setScrollByCode] = useState(false);
  const { showStatusModal } = useStatusModal();
  const [activeDay, setActiveDay] = useState(0);
  const [days, setDays] = useState(getNext30Days());
  const navigate = useNavigate();
  const eventsRefs = useRef([]);

  let targetList = [];
  const menuItems = [
    {
      text: t("session_details"),
      onClick: () => {
        detailsClick();
      },
    },

    {
      text: t("cancel_session"),
      onClick: () => setConfirmModalToggle("modal-confirm"),
    },
    {
      text: t("reschedule"),
      onClick: () => {
        resceduleClick();
      },
    },
  ];

  const handleSelectDay = (index, day, hasEvents) => {
    if (!hasEvents) {
      return;
    }
    const formattedEventDates = scheduleEventData?.map((elem) =>
      format(elem.NewScheduledDate, "EEE dd"),
    );
    const indexInEvents = formattedEventDates.indexOf(day);
    const activeElement = eventsRefs.current[indexInEvents];
    if (activeElement) {
      const rect = activeElement.getBoundingClientRect();
      const absoluteElementTop = rect.top + window.scrollY;
      setScrollByCode(true);
      window.scrollTo({
        top: absoluteElementTop - 140,
        behavior: "smooth",
      });

      setTimeout(() => {
        setScrollByCode(false);
      }, 500);
    }
    setActiveDay(index);
  };

  const handleScroll = () => {
    if (scrollByCode) {
      return;
    }
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const minusPixels = scrollTop > lastScrollTop ? 10 : 50;
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    let cumulativeHeight = 0;
    let currentDay = 0;

    for (let i = 0; i < eventsRefs.current.length; i++) {
      if (
        window.scrollY <
        cumulativeHeight + eventsRefs.current[i].offsetHeight - minusPixels
      ) {
        currentDay = i;
        break;
      }
      cumulativeHeight += eventsRefs.current[i].offsetHeight;
    }
    const filteredDays = days.filter((day) => day.hasEvents);
    if (filteredDays.length > 0) {
      const nextIndex = days.findIndex(
        (elem) => elem.date === filteredDays[currentDay].date,
      );
      setActiveDay(nextIndex);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollByCode, days, lastScrollTop]);

  async function fetchSchedule(token, buid) {
    try {
      const data = await get(
        `/BusinessAccount/${buid}/Schedules?oneTime=true&onlyWithSubscription=true`,
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchEvents(token, buid) {
    const today = new Date().toJSON();

    try {
      const data = await get(
        `/ApplicationEvent?EventDate=${today}&EventStatuses=Cancelled&EventStatuses=Rescheduled&EventStatuses=Scheduled&EventStatuses=Passed&ProviderBusinessAccountId=${buid}`,
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  function convertTimeToObject(timeString) {
    const [hour, minute, second] = timeString.split(":");
    return { hour, minute, second };
  }

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const random = Math.random().toString(36).substring(2, 8);
    return `${timestamp}${random}`;
  }

  async function getScheduledEvents() {
    try {
      const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
      const encodedToken = jwtDecode(ps10005000);
      const today = new Date();

      const schedule = await fetchSchedule(ps10005000, encodedToken.buid);
      const events = await fetchEvents(ps10005000, encodedToken.buid);

      margeSchedule(schedule, events);
    } catch (error) {
      console.error("Error displaying scheduled events:", error);
    }
  }

  function getAllDatesForWeekdayInRange(startDate, endDate, targetDayOfWeek) {
    let currentDay = startDate.getDay();
    let daysToAdd = targetDayOfWeek - currentDay;
    if (daysToAdd < 0) {
      daysToAdd += 7; // If the target day is before the current day, add 7 days to find the next occurrence
    }
    let currentDate = new Date(startDate.getTime());
    currentDate.setDate(startDate.getDate() + daysToAdd);

    const dates = [];
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate.getTime())); // Clone the date to avoid reference issues
      currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
    }
    return dates;
  }

  function getDayOfWeek(day) {
    switch (day) {
      case "Monday":
        return 1;
      case "Tuesday":
        return 2;
      case "Wednesday":
        return 3;
      case "Thursday":
        return 4;
      case "Friday":
        return 5;
      case "Saturday":
        return 6;
      case "Sunday":
        return 0;
      default:
        throw Error;
    }
  }

  const manageEvent = (eventData, targetList) => {
    eventData
      // .filter(
      //   (e) =>
      //     e.eventStatus === "Rescheduled" ||
      //     e.eventStatus === "Cancelled" ||
      //     e.eventStatus === "Scheduled",
      // )
      .forEach((item) => {
        targetList
          .filter(
            (x) =>
              x.OriginalScheduledDate.toDateString() ===
                item.originalEventDate.toDateString() &&
              x.data.some(
                (i) =>
                  i.OriginalScheduledTimeOnly.hour ===
                    ("0" + item.originalEventDate.getHours()).slice(-2) &&
                  i.OriginalScheduledTimeOnly.minute ===
                    ("0" + item.originalEventDate.getMinutes()).slice(-2) &&
                  i.scheduleId === item.scheduleId,
              ),
          )
          .forEach((i) => {
            // Updating TargetObject NewScheduledDate with EventDate
            i.NewScheduledDate = item.eventDate;

            // Updating TargetObject time and status from fetched event
            i.data.forEach((j) => {
              j.NewScheduledTimeOnly = {
                hour: ("0" + item.eventDate.getHours()).slice(-2),
                minute: ("0" + item.eventDate.getMinutes()).slice(-2),
              };
              j.Status = item.eventStatus;
              j.eventId = item.id;
            });
          });
      });

    let mergedScheduleData = [];

    targetList.forEach((item) => {
      let existingItemIndex = mergedScheduleData.findIndex(
        (mergedItem) =>
          mergedItem.NewScheduledDate.getFullYear() ===
            item.NewScheduledDate.getFullYear() &&
          mergedItem.NewScheduledDate.getMonth() ===
            item.NewScheduledDate.getMonth() &&
          mergedItem.NewScheduledDate.getDate() ===
            item.NewScheduledDate.getDate(),
      );
      if (existingItemIndex !== -1) {
        mergedScheduleData[existingItemIndex].data = mergedScheduleData[
          existingItemIndex
        ].data.concat(item.data);
      } else {
        mergedScheduleData.push(item);
      }
    });

    mergedScheduleData.sort((a, b) => a.NewScheduledDate - b.NewScheduledDate);
    const finalData = mergedScheduleData.filter(
      (elem) => !elem.data.every((e) => e.Status === "Cancelled"),
    );

    setScheduleEventData(finalData);
    const days = getNext30Days(finalData);
    const activeDayIndex = days.findIndex((e) => e.hasEvents);
    setActiveDay(activeDayIndex);
    setDays(days);
  };

  const margeSchedule = (scheduleData, eventData) => {
    if (!scheduleData.length) {
      return;
    }

    scheduleData.map((item) => {
      item.effectiveFrom = new Date(item.effectiveFrom);
      item.scheduleDays.forEach((day) => {
        day.timeOnly = convertTimeToObject(day.timeOnly);
      });
    });

    eventData.map((item) => {
      item.eventDate = new Date(item.eventDate);
      item.originalEventDate = new Date(item.originalEventDate);
    });

    const startDate = new Date();
    const endDate = new Date(
      new Date().setMonth(startDate.getMonth() + endDateIndex),
    );

    if (scheduleData.find((item) => item.isOneTime === true)) {
      scheduleData
        .filter((item) => item.isOneTime === true)
        .forEach((schedule) => {
          const oneTimedate = new Date(schedule.effectiveFrom);

          let oneTimeData = {
            WeekDay: oneTimedate.toLocaleDateString("en-US", {
              weekday: "long",
            }),
            OriginalScheduledDate: oneTimedate,
            NewScheduledDate: oneTimedate,

            data: [
              {
                eventId: null,
                guid: generateUniqueId(),
                scheduleId: schedule.id,
                dayId: null,
                OriginalScheduledTimeOnly: {
                  hour: oneTimedate.getHours().toString().padStart(2, "0"),
                  minute: oneTimedate.getMinutes().toString().padStart(2, "0"),
                  second: oneTimedate.getSeconds().toString().padStart(2, "0"),
                },
                ScheduleDescription: schedule.description,
                Status: "Scheduled",
                groupSchedule: schedule.groupSchedule,
                NewScheduledTimeOnly: {
                  hour: oneTimedate.getHours().toString().padStart(2, "0"),
                  minute: oneTimedate.getMinutes().toString().padStart(2, "0"),
                  second: oneTimedate.getSeconds().toString().padStart(2, "0"),
                },
              },
            ],
          };
          targetList.push(oneTimeData);
        });
    }

    scheduleData.forEach((schedule) => {
      schedule.scheduleDays.forEach((day) => {
        const validStarDate =
          schedule.effectiveFrom >= startDate
            ? schedule.effectiveFrom
            : startDate;
        var dates = getAllDatesForWeekdayInRange(
          validStarDate,
          endDate,
          getDayOfWeek(day.dayOfWeek),
        );
        dates.forEach((x) => {
          let tempObject = {
            WeekDay: day.dayOfWeek,
            OriginalScheduledDate: x,
            NewScheduledDate: x,
            data: [
              {
                guid: generateUniqueId(),
                scheduleId: schedule.id,
                dayId: day.id,
                OriginalScheduledTimeOnly: day.timeOnly,
                ScheduleDescription: schedule.description,
                Status: "Scheduled",
                groupSchedule: schedule.groupSchedule, // added
                NewScheduledTimeOnly: day.timeOnly,
              },
            ],
          };
          targetList.push(tempObject);
        });
      });
    });

    manageEvent(eventData, targetList);
  };

  useEffect(() => {
    try {
      getScheduledEvents();
    } catch (err) {
      console.error("Error:", err);
    }
  }, []);

  const handleToggleMenu = (item, date) => {
    setSelectedItem((prevEvent) =>
      prevEvent?.guid === item.guid ? null : item,
    );
    setSelectedEventDate(date);
  };

  const formatDate = (date) => {
    const localDate = new Date(date);
    localDate.setHours(localDate.getHours() + 4);
    return localDate.toISOString();
  };

  const displayDataFormat = (date) => {
    if (date) {
      const day = date.getDate();
      const month = monthNames[date.getMonth()];

      const year = date.getFullYear();

      const formattedDate = `${day} of ${month} ${year}`;

      return formattedDate;
    }
  };

  const detailsClick = () => {
    let eventDate = new Date(selectedEventDate.NewScheduledDate);
    eventDate.setHours(selectedItem.NewScheduledTimeOnly.hour);
    eventDate.setMinutes(selectedItem.NewScheduledTimeOnly.minute);
    eventDate.setSeconds(selectedItem.NewScheduledTimeOnly.second || "00");
    navigate(`/session/${selectedItem?.scheduleId}`, {
      state: {
        originalEventDate: eventDate,
        dayId: selectedItem.dayId,
        eventId: selectedItem.eventId,

        // for session cancelation
        NewScheduledDate: selectedEventDate.NewScheduledDate,
        OriginalScheduledDate: selectedEventDate.OriginalScheduledDate,
        NewScheduledTimeOnly: selectedItem.NewScheduledTimeOnly,
        OriginalScheduledTimeOnly: selectedItem.OriginalScheduledTimeOnly,
      },
    });
  };

  const resceduleClick = () => {
    let eventDate = new Date(selectedEventDate.NewScheduledDate);
    eventDate.setHours(selectedItem.NewScheduledTimeOnly.hour);
    eventDate.setMinutes(selectedItem.NewScheduledTimeOnly.minute);
    eventDate.setSeconds(selectedItem.NewScheduledTimeOnly.second || "00");
    navigate(`/reschedule/${selectedItem?.scheduleId}`, {
      state: {
        originalEventDate: eventDate,
        dayId: selectedItem.dayId,
        eventId: selectedItem.eventId,
      },
    });
  };
  const cancelSession = async () => {
    try {
      const method = selectedItem.eventId ? patch : post;
      const eventTime = selectedEventDate.NewScheduledDate;
      const originalEventTime = selectedEventDate.OriginalScheduledDate;

      eventTime.setHours(selectedItem.NewScheduledTimeOnly.hour);
      eventTime.setMinutes(selectedItem.NewScheduledTimeOnly.minute);
      eventTime.setSeconds(selectedItem.NewScheduledTimeOnly.second || "00");
      originalEventTime.setHours(selectedItem.OriginalScheduledTimeOnly.hour);
      originalEventTime.setMinutes(
        selectedItem.OriginalScheduledTimeOnly.minute,
      );
      originalEventTime.setSeconds(
        selectedItem.OriginalScheduledTimeOnly.second || "00",
      );

      const dataToPass = selectedItem.eventId
        ? {
            id: selectedItem.eventId,
            eventStatus: "Cancelled",
          }
        : {
            scheduleDayId: selectedItem.dayId,
            eventTime: formatDate(eventTime),
            originalEventTime: formatDate(originalEventTime),
            eventStatus: "Cancelled",
            scheduleId: selectedItem.scheduleId,
          };

      const data = await method("/ApplicationEvent", dataToPass);
      if (data) {
        setSelectedItem(null);
        const successMessage = t("consumer_schedules_cancel_success");
        showStatusModal(successMessage);
        getScheduledEvents();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const scrollItems = days.map((item) => ({
    ...item,
    subText: t(item.dayName).slice(0, 3),
  }));

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ModalConfirmation
        modalText={t("cancel_session_confirmation_modal_text", {
          date: displayDataFormat(selectedEventDate?.NewScheduledDate),
        })}
        modalSubText={t("cancel_session_confirmation_modal_subText")}
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        confirm={cancelSession}
      />
      <div className="provider-container">
        <ScrollableItems
          items={scrollItems}
          activeItem={activeDay}
          selectItem={handleSelectDay}
        />
        {!scheduleEventData ? (
          <div className="empty-schedule">
            <h1>{t("provider_sessions_empty")}</h1>
          </div>
        ) : (
          scheduleEventData.map((day, dayIndex) => {
            return (
              <div
                className="schedule-list-container"
                key={dayIndex}
                ref={(el) => (eventsRefs.current[dayIndex] = el)}
              >
                <div className="schedule-day">
                  <span>
                    {t(day.WeekDay.toLocaleLowerCase())} -{" "}
                    {day.NewScheduledDate.getDate()}{" "}
                    {t(
                      day.NewScheduledDate.toLocaleString("default", {
                        month: "long",
                      }).toLocaleLowerCase(),
                    )}
                  </span>
                </div>
                <ul>
                  {day.data
                    .sort(
                      (a, b) =>
                        a.NewScheduledTimeOnly.hour -
                        b.NewScheduledTimeOnly.hour,
                    )
                    .filter((elem) => elem.Status !== "Cancelled")
                    .map((item, itemIndex) => {
                      return (
                        <li key={itemIndex}>
                          <div className="schedule-container">
                            <div className="schedule-description">
                              <span>{item.ScheduleDescription}</span>
                            </div>
                            <div className="schedule-icon-container">
                              <div className="schedule-icon">
                                {item.groupSchedule === true ? (
                                  <Group />
                                ) : (
                                  <Single />
                                )}
                                <div className="schedule-line"></div>
                              </div>
                            </div>
                            <div className="schedule">
                              <div className="schedule-time">
                                <span>
                                  {item.NewScheduledTimeOnly.hour}:
                                  {item.NewScheduledTimeOnly.minute}
                                </span>
                              </div>

                              <div className="schedule-icons">
                                <DotsMenu
                                  menuItems={menuItems}
                                  isMenuOpen={
                                    selectedItem?.guid === item.guid &&
                                    !confirmModalToggle
                                  }
                                  toggleMenu={() => handleToggleMenu(item, day)}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default React.memo(ScheduleProvider);
