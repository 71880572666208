import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Weekdays = ({ weekdaysData }) => {
  const { t } = useTranslation();

  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");

  const checkDetailsDays = () => {
    if (weekdaysData) {
      weekdaysData.forEach((day) => {
        if (day.dayOfWeek === "Monday") {
          setMonday(["active", day.timeOnly.slice(0, 5)]);
        }
        if (day.dayOfWeek === "Tuesday") {
          setTuesday(["active", day.timeOnly.slice(0, 5)]);
        }
        if (day.dayOfWeek === "Wednesday") {
          setWednesday(["active", day.timeOnly.slice(0, 5)]);
        }
        if (day.dayOfWeek === "Thursday") {
          setThursday(["active", day.timeOnly.slice(0, 5)]);
        }
        if (day.dayOfWeek === "Friday") {
          setFriday(["active", day.timeOnly.slice(0, 5)]);
        }
        if (day.dayOfWeek === "Saturday") {
          setSaturday(["active", day.timeOnly.slice(0, 5)]);
        }
        if (day.dayOfWeek === "Sunday") {
          setSunday(["active", day.timeOnly.slice(0, 5)]);
        }
      });
    }
  };

  useEffect(() => {
    checkDetailsDays();
  }, []);

  return (
    <div className="weekdays">
      <div className={monday[0]}>
        <span>{t("monday").slice(0, 2)}</span> <span>{monday[1]}</span>
      </div>
      <div className={tuesday[0]}>
        {" "}
        <span>{t("tuesday").slice(0, 2)}</span> <span>{tuesday[1]}</span>
      </div>
      <div className={wednesday[0]}>
        {" "}
        <span>{t("wednesday").slice(0, 2)}</span> <span>{wednesday[1]}</span>
      </div>
      <div className={thursday[0]}>
        {" "}
        <span>{t("thursday").slice(0, 2)}</span> <span>{thursday[1]}</span>
      </div>
      <div className={friday[0]}>
        {" "}
        <span>{t("friday").slice(0, 2)}</span> <span>{friday[1]}</span>
      </div>
      <div className={saturday[0]}>
        {" "}
        <span>{t("saturday").slice(0, 2)}</span> <span>{saturday[1]}</span>
      </div>
      <div className={sunday[0]}>
        {" "}
        <span>{t("sunday").slice(0, 2)}</span> <span>{sunday[1]}</span>
      </div>
    </div>
  );
};

export default Weekdays;
