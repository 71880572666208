import { ReactComponent as QuestionIcon } from "../../static/images/question_sign.svg";
import { useTranslation } from "react-i18next";

import "./style.scss";

const ModalConfirmation = ({
  modalText,
  modalSubText,
  changeModal,
  modalValue,
  cancelButtonText,
  confirmButtonText,
  confirm,
  cancel,
  inputModal,
}) => {
  const { t } = useTranslation();

  const handleConfirm = async () => {
    try {
      changeModal("");
      confirm();
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className={`modal-container ${modalValue}`}>
      <div className="modal-content">
        <div className="modal-image">
          <QuestionIcon />
        </div>
        <div className="modal-text">
          <h3>{modalText}</h3>
        </div>
        <div className="modal-sub-text">
          <span>{modalSubText}</span>
        </div>
        <div className="input-modal">{inputModal}</div>
        <div className="modal-buttons">
          <div>
            <button id="modal-confirm" onClick={() => handleConfirm()}>
              {confirmButtonText ? confirmButtonText : t("confirm")}{" "}
            </button>
          </div>
          <div>
            <button
              id="modal-censel"
              onClick={(e) => {
                changeModal("");
                if (cancel) {
                  cancel(e);
                }
              }}
            >
              {cancelButtonText ? cancelButtonText : t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmation;
