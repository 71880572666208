import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translation files
import en from "./locales/en.json";
import ka from "./locales/ka.json";

const savedLanguage = localStorage.getItem("language");

// i18n initialization
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    ka: { translation: ka },
  },
  lng: savedLanguage, // Default language
  fallbackLng: "en", // Fallback language if translation is missing
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ["localStorage"],
    caches: ["localStorage"],
  },
});

export default i18n;
