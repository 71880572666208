import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import platform from "platform";
import { useTranslation } from "react-i18next";

import "./style.css";

const AddToHome = () => {
  const [promptToggle, setPromptToggle] = useState("");
  const [prompt, setPrompt] = useState(null);
  const { pasthname } = useLocation();
  const { t } = useTranslation();

  const osPlatform = platform.os.family;

  let modalStorage =
    "M0M2MiLCJlbWFpbCI6ImFsZXhAZ21haWwuY29tIiwidWkIjoiMSIsInJvbGVzIjoi";

  useEffect(() => {
    const handler = (event) => {
      setPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const handleAddToHomeScreenClick = () => {
    if (prompt) {
      prompt.prompt();
    }

    setPromptToggle("disabled");
    localStorage.setItem("ps10003000", modalStorage);
  };

  if (window.matchMedia("(display-mode: standalone)").matches) {
    return;
  }

  if (localStorage.ps10003000) {
    return;
  }

  if (!localStorage.ps10005000) {
    return;
  }

  return (
    <div className={`install-modal-container ${promptToggle}`}>
      <div className="install-modal">
        {osPlatform == "iOS" ? (
          <>
            <div className="install-modal-text">
              <h1>{t("pwa_install")}</h1>

              <h2>{t("pwa_install_info")}</h2>
            </div>

            <div className="install-ios-steps">
              <ol>
                <li>
                  <div>
                    <span> {t("pwa_install_ios_step1")}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <span>{t("pwa_install_ios_step2")}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <span>{t("pwa_install_ios_step3")}</span>
                  </div>
                </li>
              </ol>
            </div>

            <button
              onClick={() => {
                setPromptToggle("disabled");
              }}
            >
              {t("close")}
            </button>
            <div className="no-ask">
              <input
                type="checkbox"
                id="ask"
                onChange={(e) => {
                  if (e.target.checked) {
                    localStorage.setItem("ps10003000", modalStorage);
                  } else {
                    localStorage.removeItem("ps10003000");
                  }
                }}
              />
              <label htmlFor="ask">{t("dont_ask_again")}</label>
            </div>
          </>
        ) : null}

        {osPlatform === "iOS" ? null : (
          <>
            <div className="install-modal-text">
              <h1>{t("pwa_install")}</h1>
              <h2>{t("pwa_install_info")}</h2>
            </div>
            <button
              onClick={() => {
                handleAddToHomeScreenClick();
              }}
            >
              {t("pwa_install")}
            </button>
            <button
              onClick={() => {
                setPromptToggle("disabled");
              }}
            >
              {t("close")}
            </button>
            <div className="no-ask">
              <input
                type="checkbox"
                id="ask"
                onChange={(e) => {
                  if (e.target.checked) {
                    localStorage.setItem("ps10003000", modalStorage);
                  } else {
                    localStorage.removeItem("ps10003000");
                  }
                }}
              />
              <label htmlFor="ask">{t("dont_ask_again")}</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddToHome;
