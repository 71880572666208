import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useApi from "../../hooks/useApi";
import { useStatusModal } from "../../context/StatusModalContext";

import { jwtDecode } from "jwt-decode";
import { format } from "date-fns";

import { weekDays } from "../../store/GlobalVarriables";
import ScheduleInviteInput from "../../Components/ScheduleInviteInput/ScheduleInviteInput";

import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";

import "./style.scss";
import CustomSelect from "../../Components/CustomSelect/CustomSelect";

const ScheduleEdit = () => {
  const { patch, get, post, del, loading } = useApi();

  const navigate = useNavigate();
  const { scheduleId } = useParams();
  const timeInputRef = useRef(null);
  const { showStatusModal } = useStatusModal();
  const { t } = useTranslation();

  const [confirmModalToggle, setConfirmModalToggle] = useState("");
  const [cancelModalToggle, setCancelModalToggle] = useState("");

  // shcedule details
  const [initialValues, setInitialValues] = useState({});
  const [currentValues, setCurrentValues] = useState({});

  // labels
  const [maxMembersLabel, setMaxMembersLabel] = useState(
    t("input_label_max_number"),
  );
  const [scheduleNameLabel, setScheduleNameLabel] = useState(
    t("input_label_schedule_name"),
  );
  const [startDateLabel, setStartDateLabel] = useState(
    t("input_label_start_date"),
  );
  const [priceLabel, setPriceLabel] = useState(t("input_label_price"));
  const [durationLabel, setDurationLabel] = useState(
    t("input_label_event_duration"),
  );
  const [cancelationGraceLabel, setCancelationGraceLabel] = useState(
    t("input_label_cancelation_grace"),
  );

  const [ofSessionBillingLabel, setOfSessionBillingLabel] = useState(
    t("input_label_of_sessions_billing"),
  );
  const [ofSessionPrePayLabel, setOfSessionPrePayLabel] = useState(
    t("input_label_of_sessions_prepayment"),
  );

  const [eventDateLable, setEventDateLabel] = useState(
    t("input_label_event_date"),
  );
  const [eventTimeLabel, setEventTimeLabel] = useState(
    t("input_label_event_time"),
  );

  const [cityLabel, setCityLabel] = useState(t("input_label_city"));
  const [districtLabel, setDistrictLabel] = useState(t("input_label_district"));
  const [addressLabel, setAddressLabel] = useState(t("input_label_address"));

  // date picker
  const [initilaDayAndTime, setInitialDays] = useState({});
  const [selectedDayAndTime, setSelectedDayAndTime] = useState({});
  const [currentDay, setCurrentDay] = useState(null);
  const [timePickerVisible, setTimePickerVisible] = useState(false);
  const [dateHasError, setDateHasError] = useState(false);

  const fetchDetails = async () => {
    try {
      const data = await get(`/Schedule/${scheduleId}`);
      const type = data.isOneTime
        ? "one-time"
        : data.groupSchedule
          ? "group"
          : "individual";
      const effectiveFrom = format(data.effectiveFrom, "yyyy-MM-dd");
      const eventTime = format(data.effectiveFrom, "hh:mm");
      const billingPeriod =
        data.financialDetails.billingPeriod === "Based_On_Session_Events"
          ? "0"
          : data.financialDetails.billingPeriod === "End_Of_The_Month"
            ? "1"
            : "2";
      const scheduleDayAndTime = data.scheduleDays.reduce((acc, item) => {
        acc[item.dayOfWeek] = item.timeOnly;
        return acc;
      }, {});
      setInitialDays(data.scheduleDays);
      setSelectedDayAndTime(scheduleDayAndTime);

      const initialValues = {
        groupType: type,
        maxMembers: data.maxMembers,
        scheduleName: data.description,
        startDate: effectiveFrom,
        eventDate: effectiveFrom,
        eventTime,
        duration: data.duration,
        cancelSchedule: data.customerCanCancel,
        cancelationGrace: data.cancellationGraceHours,
        whoPays: data.financialDetails.payerOption === "Group" ? "1" : "0",
        priceType:
          data.financialDetails.pricingType === "PerSession" ? "0" : "1",
        price: data.price,
        billingPeriod,
        ofSessionBilling: data.financialDetails.billingEventCount,
        paymentType:
          data.financialDetails.paymentType === "PrePaid" ? "0" : "1",
        ofSessionPrePay: data.financialDetails.prepaymentEventCount,
      };
      setInitialValues((prevValues) => ({
        ...prevValues,
        ...initialValues,
      }));
      setCurrentValues((prevValues) => ({
        ...prevValues,
        ...initialValues,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAddress = async () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));

    const decodedToken = jwtDecode(ps10005000);

    try {
      const data = await get(`/BusinessAccount/${decodedToken.buid}`);
      const addressObject = {
        city: data.city,
        district: data.district,
        address: data.address,
      };
      setInitialValues((prevValues) => ({
        ...prevValues,
        ...addressObject,
      }));
      setCurrentValues((prevValues) => ({
        ...prevValues,
        ...addressObject,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchAddress();
  }, []);

  const handleFieldChange = (name, value) => {
    setCurrentValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const changeLabelHandler = (lable, value) => {
    let numberValidation = /^[1-9]\d*(\.\d+)?$|^0(\.\d+)?$/;

    if (lable === "date") {
      if (value) {
        setStartDateLabel(t("input_label_start_date"));
      }
    }
    if (lable === "event-date") {
      if (value) {
        setEventDateLabel(t("input_label_event_date"));
      }
    }
    if (lable === "event-time") {
      if (value) {
        setEventTimeLabel(t("input_label_event_time"));
      }
    }

    if (currentValues.groupType === "group") {
      if (lable === "member") {
        if (value.length === 0) {
          setMaxMembersLabel(t("error_max_member_required"));
        } else if (value[0] === "0") {
          setMaxMembersLabel(t("error_start_with_zero"));
        } else if (value.includes(",") || value.includes(".")) {
          setMaxMembersLabel(t("error_is_not_whole"));
        } else if (!numberValidation.test(value)) {
          setMaxMembersLabel(t("error_is_not_number"));
        } else {
          setMaxMembersLabel(t("input_label_max_number"));
        }
      }
    }

    if (lable === "name") {
      if (value.length === 0) {
        setScheduleNameLabel(t("error_schedule_name_required"));
      } else {
        setScheduleNameLabel(t("input_label_schedule_name"));
      }
    }

    if (lable === "price") {
      if (value.length === 0) {
        setPriceLabel(t("error_price_required"));
      } else if (!numberValidation.test(value)) {
        setPriceLabel(t("error_is_not_number"));
      } else {
        setPriceLabel(t("input_label_price"));
      }
    }
    if (lable === "duration") {
      if (value.length === 0) {
        setDurationLabel(t("error_event_duration_required"));
      } else if (value[0] === "0") {
        setDurationLabel(t("error_start_with_zero"));
      } else if (!numberValidation.test(value)) {
        setDurationLabel(t("error_is_not_number"));
      } else {
        setDurationLabel(t("input_label_event_duration"));
      }
    }
    if (lable === "cancelation-grace") {
      if (value.length === 0) {
        setCancelationGraceLabel(t("error_cancelation_grace_required"));
      } else if (value[0] === "0") {
        setCancelationGraceLabel(t("error_start_with_zero"));
      } else if (value.includes(",") || value.includes(".")) {
        setCancelationGraceLabel(t("error_is_not_whole"));
      } else if (!numberValidation.test(value)) {
        setCancelationGraceLabel(t("error_is_not_number"));
      } else {
        setCancelationGraceLabel(t("input_label_cancelation_grace"));
      }
    }

    if (lable === "of-session-billing") {
      if (value.length === 0) {
        setOfSessionBillingLabel(t("error_of_seesion_billing_required"));
      } else if (!numberValidation.test(value)) {
        setOfSessionBillingLabel(t("error_is_not_number"));
      } else {
        setOfSessionBillingLabel(t("input_label_of_sessions_billing"));
      }
    }
    if (lable === "of-session-prePay") {
      if (value.length === 0) {
        setOfSessionPrePayLabel(t("error_of_session_prepaid_required"));
      } else if (!numberValidation.test(value)) {
        setOfSessionPrePayLabel(t("error_is_not_number"));
      } else {
        setOfSessionPrePayLabel(t("input_label_of_sessions_prepayment"));
      }
    }
    if (lable === "city") {
      if (value.length === 0) {
        setCityLabel(t("error_city_required"));
      } else {
        setCityLabel(t("input_label_city"));
      }
    }
    if (lable === "district") {
      if (value.length === 0) {
        setDistrictLabel(t("error_district_required"));
      } else {
        setDistrictLabel(t("input_label_district"));
      }
    }
    if (lable === "address") {
      if (value.length === 0) {
        setAddressLabel(t("error_address_required"));
      } else {
        setAddressLabel(t("input_label_address"));
      }
    }
  };

  const saveButtonClick = () => {
    if (
      currentValues.groupType === "group" &&
      currentValues.maxMembers.length === 0
    ) {
      setMaxMembersLabel(t("error_max_member_required"));
    }

    if (currentValues.scheduleName === "") {
      setScheduleNameLabel(t("error_schedule_name_required"));
    } else {
      setScheduleNameLabel(t("input_label_schedule_name"));
    }
    if (currentValues.price === "") {
      setPriceLabel(t("error_price_required"));
    }
    if (currentValues.duration === "") {
      setDurationLabel(t("error_event_duration_required"));
    }

    if (!currentValues.startDate) {
      setStartDateLabel(t("error_start_date_required"));
    }

    if (Object.entries(selectedDayAndTime).length === 0) {
      setDateHasError(true);
      return;
    }

    if (currentValues.cancelationGrace === "") {
      setCancelationGraceLabel(t("error_cancelation_grace_required"));
    }
    if (currentValues.city === "") {
      setCityLabel(t("error_city_required"));
    }
    if (currentValues.district === "") {
      setDistrictLabel(t("error_district_required"));
    }
    if (currentValues.address === "") {
      setAddressLabel(t("error_address_required"));
    }

    if (currentValues.ofSessionBilling === "") {
      setOfSessionBillingLabel(t("error_of_seesion_billing_required"));
      return;
    }

    if (currentValues.ofSessionPrePay === "") {
      setOfSessionPrePayLabel(t("error_of_session_prepaid_required"));
      return;
    }

    if (!currentValues.eventDate) {
      setEventDateLabel(t("error_event_date_required"));
    }
    if (!currentValues.eventTime) {
      setEventTimeLabel(t("error_event_time_required"));
    }

    if (currentValues.groupType === "individual") {
      if (
        currentValues.scheduleName.length !== 0 &&
        !scheduleNameLabel.includes("!") &&
        currentValues.price.length !== 0 &&
        !priceLabel.includes("!") &&
        currentValues.duration.length !== 0 &&
        !durationLabel.includes("!") &&
        !cancelationGraceLabel.includes("!") &&
        currentValues.startDate.length !== 0 &&
        !startDateLabel.includes("!") &&
        currentValues.city.length !== 0 &&
        !cityLabel.includes("!") &&
        !currentValues.district.length !== 0 &&
        !districtLabel.includes("!") &&
        currentValues.address.length !== 0 &&
        !addressLabel.includes("!") &&
        !ofSessionBillingLabel.includes("!") &&
        !ofSessionPrePayLabel.includes("!")
      ) {
        setConfirmModalToggle("modal-confirm");
      }
    } else if (currentValues.groupType === "group") {
      if (
        currentValues.scheduleName.length !== 0 &&
        !scheduleNameLabel.includes("!") &&
        currentValues.price.length !== 0 &&
        !priceLabel.includes("!") &&
        currentValues.duration.length !== 0 &&
        !durationLabel.includes("!") &&
        !cancelationGraceLabel.includes("!") &&
        currentValues.startDate.length !== 0 &&
        !startDateLabel.includes("!") &&
        currentValues.maxMembers.length !== 0 &&
        !maxMembersLabel.includes("!") &&
        currentValues.city.length !== 0 &&
        !cityLabel.includes("!") &&
        !currentValues.district.length !== 0 &&
        !districtLabel.includes("!") &&
        currentValues.address.length !== 0 &&
        !addressLabel.includes("!") &&
        !ofSessionBillingLabel.includes("!") &&
        !ofSessionPrePayLabel.includes("!")
      ) {
        setConfirmModalToggle("modal-confirm");
      }
    } else if (currentValues.groupType === "one-time") {
      if (
        currentValues.scheduleName.length !== 0 &&
        !scheduleNameLabel.includes("!") &&
        currentValues.price.length !== 0 &&
        !priceLabel.includes("!") &&
        currentValues.duration.length !== 0 &&
        !durationLabel.includes("!") &&
        !cancelationGraceLabel.includes("!") &&
        currentValues.eventDate.length !== 0 &&
        !eventDateLable.includes("!") &&
        currentValues.eventTime.length !== 0 &&
        !eventTimeLabel.includes("!") &&
        currentValues.city.length !== 0 &&
        !cityLabel.includes("!") &&
        !currentValues.district.length !== 0 &&
        !districtLabel.includes("!") &&
        currentValues.address.length !== 0 &&
        !addressLabel.includes("!")
      ) {
        {
          setConfirmModalToggle("modal-confirm");
        }
      }
    }
  };

  const removeEmptyKeys = (obj) => {
    for (let key in obj) {
      const value = obj[key];

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        removeEmptyKeys(value);

        if (Object.keys(value).length === 0) {
          delete obj[key];
        }
      } else if (value === undefined || value === null) {
        delete obj[key];
      }
    }

    return Object.keys(obj).length === 0 ? null : obj;
  };

  const editSchedule = async () => {
    const changedValues = {};
    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== initialValues[key]) {
        changedValues[key] = currentValues[key];
      }
    });

    const finalData = {
      effectiveFrom: changedValues.startDate || undefined,
      description: changedValues.scheduleName || undefined,
      maxMembers: +changedValues.maxMembers || undefined,
      duration: +changedValues.duration || undefined,
      cancellationGraceHours:
        +changedValues.cancelationGrace >= 0
          ? +changedValues.cancelationGrace
          : undefined,
      customerCanCancel: changedValues.cancelSchedule,
      price: +changedValues.price || undefined,
      location: {
        city: changedValues.city || undefined,
        district: changedValues.district || undefined,
        address: changedValues.address || undefined,
      },
      financialDetails: {
        payerOption: +changedValues.whoPays || undefined,
        pricingType: +changedValues.priceType || undefined,
        billingPeriod: +changedValues.billingPeriod || undefined,
        billingEventCount: +changedValues.ofSessionBilling || undefined,
        paymentType:
          changedValues.billingPeriod === "1"
            ? 1
            : changedValues.billingPeriod === "2"
              ? 0
              : changedValues.billingPeriod === "0"
                ? +changedValues.paymentType
                : undefined,
        prepaymentEventCount:
          changedValues.paymentType === "0" &&
          changedValues.billingPeriod === "0"
            ? +changedValues.ofSessionPrePay
            : undefined,
      },
    };

    const scheduleDays = Object.entries(selectedDayAndTime).map(
      ([dayOfWeek, timeOnly]) => ({ timeOnly, dayOfWeek }),
    );
    const deletedDays = initilaDayAndTime.filter(
      (initialItem) =>
        !scheduleDays.some(
          (selecteditem) => selecteditem.dayOfWeek === initialItem.dayOfWeek,
        ),
    );
    const addedDays = scheduleDays.filter(
      (selecteditem) =>
        !initilaDayAndTime.some(
          (initialItem) => initialItem.dayOfWeek === selecteditem.dayOfWeek,
        ),
    );
    const modifiedDays = scheduleDays.flatMap((selecteditem) => {
      const matchingItem = initilaDayAndTime.find(
        (initialItem) =>
          initialItem.dayOfWeek === selecteditem.dayOfWeek &&
          initialItem.timeOnly !== selecteditem.timeOnly,
      );
      if (matchingItem) {
        return { ...selecteditem, id: matchingItem.id };
      }
      return [];
    });

    try {
      let daysChanged = false;
      if (deletedDays.length > 0) {
        deletedDays.forEach((item) => {
          del(`/ScheduleDay/${item.id}`);
        });
        daysChanged = true;
      }

      if (addedDays.length > 0) {
        addedDays.forEach((item) => {
          post("/ScheduleDay", { ...item, scheduleId });
        });
        daysChanged = true;
      }
      if (modifiedDays.length > 0) {
        modifiedDays.forEach((item) => {
          patch("/ScheduleDay", item);
        });
        daysChanged = true;
      }

      const dataToPass = removeEmptyKeys(finalData);
      if (dataToPass) {
        const response = await patch("/Schedule", {
          id: scheduleId,
          ...dataToPass,
        });
        if (response) {
          showStatusModal(
            t("schedule_edit_success"),
            `/schedule/${scheduleId}`,
          );
        }
      } else {
        if (daysChanged) {
          showStatusModal(
            t("schedule_edit_success"),
            `/schedule/${scheduleId}`,
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectDay = (day) => {
    setSelectedDayAndTime((prevTimes) => {
      if (Object.keys(prevTimes).includes(day)) {
        setTimePickerVisible(false);
      } else {
        setCurrentDay(day);
        setTimePickerVisible(true);
      }
      const updatedTimes = { ...prevTimes };
      delete updatedTimes[day];
      return updatedTimes;
    });
  };

  const handleTimeChange = (event) => {
    const time = event.target.value;
    setSelectedDayAndTime((prevTimes) => ({
      ...prevTimes,
      [currentDay]: `${time}:00`,
    }));
    setDateHasError(false);
  };

  useEffect(() => {
    if (timePickerVisible && timeInputRef.current) {
      timeInputRef.current.focus();
      timeInputRef.current.click();
    }
  }, [timePickerVisible]);

  return (
    <div className="invite-container">
      <ModalConfirmation
        modalText={t("create_invitation_confirm_text")}
        modalSubText={t("edit_schedule_confirm_subtext")}
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        confirm={editSchedule}
      />

      <ModalConfirmation
        modalText={t("page_leave_warning")}
        changeModal={setCancelModalToggle}
        modalValue={cancelModalToggle}
        confirm={() => navigate(-1)}
      />

      <h2 className="create-invitatiion-header">
        {t("edit_schedule_page_header")}
      </h2>

      {initialValues && (
        <div className="form-container">
          <form>
            <div className="form-section-header">
              <div>
                <span>{t("schedule_section_header")}</span>
              </div>
            </div>
            <div
              className="form-section-divider"
              style={{
                marginTop: "40px",
              }}
            >
              <CustomSelect
                options={[
                  {
                    label: t("create_invitation_type_individual"),
                    value: "individual",
                  },
                  { label: t("create_invitation_type_group"), value: "group" },
                  {
                    label: t("create_invitation_type_oneTime"),
                    value: "one-time",
                  },
                ]}
                defaultValue={currentValues.groupType}
                disabled
                label={t("select_label_group_type")}
                onSelect={(val) => handleFieldChange("groupType", val)}
                optionAction={(value) => {
                  if (value === "one-time") {
                    setCurrentValues((prevvalues) => ({
                      ...prevvalues,
                      ofSessionBilling: null,
                      ofSessionPrePay: null,
                    }));

                    setOfSessionBillingLabel("");
                    setOfSessionPrePayLabel("");
                  } else {
                    setOfSessionBillingLabel(
                      t("input_label_of_sessions_billing"),
                    );
                    setOfSessionPrePayLabel(
                      t("input_label_of_sessions_prepayment"),
                    );
                    setCurrentValues((prevvalues) => ({
                      ...prevvalues,
                      ofSessionBilling: "",
                      ofSessionPrePay: "",
                      billingPeriod: "0",
                    }));
                  }
                }}
              />

              <ScheduleInviteInput
                name="name"
                label={scheduleNameLabel}
                changeValue={(val) => handleFieldChange("scheduleName", val)}
                autoComplete="name"
                changeLableValue={changeLabelHandler}
                value={currentValues.scheduleName || ""}
              />

              {currentValues.groupType === "group" ? (
                <ScheduleInviteInput
                  name="maxMember"
                  label={maxMembersLabel}
                  changeValue={(val) => handleFieldChange("maxMembers", val)}
                  changeLableValue={changeLabelHandler}
                  value={currentValues.maxMembers || ""}
                />
              ) : null}

              {currentValues.groupType === "group" ||
              currentValues.groupType === "individual" ? (
                <>
                  <div className="input-date">
                    <input
                      id="date"
                      type="date"
                      onChange={(e) => {
                        changeLabelHandler("date", e.target.value);
                        handleFieldChange("startDate", e.target.value);
                      }}
                      defaultValue={currentValues.startDate}
                    />
                    <label
                      htmlFor="date"
                      style={
                        startDateLabel.includes("!") ||
                        startDateLabel.includes("required")
                          ? { color: "red" }
                          : { color: "rgb(143, 142, 142)" }
                      }
                    >
                      {startDateLabel}
                    </label>
                  </div>
                  <div className="days-selection">
                    <div className="details-days">
                      {weekDays.map((day, index) => (
                        <div
                          key={index}
                          onClick={() => handleSelectDay(day)}
                          className={
                            Object.keys(selectedDayAndTime).includes(day)
                              ? "active"
                              : null
                          }
                        >
                          {day.slice(0, 3)}
                          {selectedDayAndTime[day] && (
                            <span>{selectedDayAndTime[day].slice(0, 5)}</span>
                          )}
                        </div>
                      ))}
                    </div>
                    {timePickerVisible && (
                      <div className="time-picker-modal">
                        <input
                          type="time"
                          onChange={handleTimeChange}
                          value={selectedDayAndTime[currentDay] || ""}
                          ref={timeInputRef}
                          onBlur={(e) => {
                            setTimePickerVisible(false);
                          }}
                        />
                      </div>
                    )}

                    <span
                      className={
                        dateHasError
                          ? "dateSelectionError"
                          : "dateSelectionLabel"
                      }
                    >
                      {t(
                        dateHasError
                          ? "error_days_and_time_required"
                          : "select_label_days",
                      )}
                    </span>
                  </div>
                </>
              ) : null}

              {currentValues.groupType === "one-time" ? (
                <>
                  <div className="input-date">
                    <input
                      id="one-time-date"
                      type="date"
                      value={currentValues.eventDate}
                      onChange={(e) => {
                        changeLabelHandler("event-date", e.target.value);
                        handleFieldChange("eventDate", e.target.value);
                      }}
                    />
                    <label
                      htmlFor="one-time-date"
                      style={
                        eventDateLable.includes("!") ||
                        eventDateLable.includes("required")
                          ? { color: "red" }
                          : { color: "rgb(143, 142, 142)" }
                      }
                    >
                      {eventDateLable}
                    </label>
                  </div>

                  <div className="input-date">
                    <input
                      id="one-time-houres"
                      type="time"
                      value={currentValues.eventTime}
                      onChange={(e) => {
                        changeLabelHandler("event-time", e.target.value);
                        handleFieldChange("eventTime", e.target.value);
                      }}
                    />
                    <label
                      htmlFor="one-time-houres"
                      style={
                        eventTimeLabel.includes("!") ||
                        eventTimeLabel.includes("required")
                          ? { color: "red" }
                          : { color: "rgb(143, 142, 142)" }
                      }
                    >
                      {eventTimeLabel}
                    </label>
                  </div>
                </>
              ) : null}

              <ScheduleInviteInput
                name="duration"
                label={durationLabel}
                changeValue={(val) => handleFieldChange("duration", val)}
                changeLableValue={changeLabelHandler}
                value={currentValues.duration || ""}
              />
              <CustomSelect
                options={
                  currentValues.cancelSchedule === true
                    ? [
                        {
                          label: t("yes"),
                          value: true,
                        },
                        { label: t("no"), value: false },
                      ]
                    : [
                        {
                          label: t("no"),
                          value: false,
                        },
                        { label: t("yes"), value: true },
                      ]
                }
                defaultValue={currentValues.cancelSchedule}
                label={t("select_label_customer_can_cancel")}
                onSelect={(val) => handleFieldChange("cancelSchedule", val)}
                optionAction={(value) => {
                  if (value === false) {
                    setCancelationGraceLabel("");
                    setCurrentValues((prevvalues) => ({
                      ...prevvalues,
                      cancelationGrace: 0,
                    }));
                  } else {
                    setCancelationGraceLabel(
                      t("input_label_cancelation_grace"),
                    );
                    setCurrentValues((prevvalues) => ({
                      ...prevvalues,
                      cancelationGrace: "",
                    }));
                  }
                }}
              />
              {currentValues.cancelSchedule === true ? (
                <ScheduleInviteInput
                  name={"cancelation-grace"}
                  label={cancelationGraceLabel}
                  changeValue={(val) =>
                    handleFieldChange("cancelationGrace", val)
                  }
                  changeLableValue={changeLabelHandler}
                  value={currentValues.cancelationGrace}
                />
              ) : null}
            </div>

            <div className="form-section-header">
              <div>
                <span>{t("financial_section_header")}</span>
              </div>
            </div>
            <div className="form-section-divider">
              {currentValues.groupType == "group" ? (
                <CustomSelect
                  options={[
                    {
                      label: t("create_invitation_payment_method_individually"),
                      value: "0",
                    },
                    {
                      label: t("create_invitation_payment_method_group"),
                      value: "1",
                    },
                  ]}
                  label={t("select_label_who_pays")}
                  defaultValue={currentValues.whoPays}
                  onSelect={(val) => handleFieldChange("whoPays", val)}
                />
              ) : null}
              {currentValues.groupType === "group" ||
              currentValues.groupType == "individual" ? (
                <CustomSelect
                  options={[
                    {
                      label: t("create_invitation_pricing_type_perSession"),
                      value: "1",
                    },
                    {
                      label: t("create_invitation_pricing_type_monthly"),
                      value: "0",
                    },
                  ]}
                  label={t("select_label_pricing_type")}
                  defaultValue={currentValues.priceType}
                  onSelect={(val) => handleFieldChange("priceType", val)}
                />
              ) : null}

              <ScheduleInviteInput
                name="price"
                label={priceLabel}
                changeValue={(val) => handleFieldChange("price", val)}
                changeLableValue={changeLabelHandler}
                value={currentValues.price || ""}
              />

              {currentValues.groupType === "group" ||
              currentValues.groupType == "individual" ? (
                <CustomSelect
                  options={[
                    {
                      label: t("create_invitation_billing_based_on_events"),
                      value: "0",
                    },
                    {
                      label: t("create_invitation_billing_month_end"),
                      value: "1",
                    },
                    {
                      label: t("create_invitation_billing_month_start"),
                      value: "2",
                    },
                  ]}
                  label={t("select_label_billing_period")}
                  defaultValue={currentValues.billingPeriod}
                  onSelect={(val) => handleFieldChange("billingPeriod", val)}
                  optionAction={(value) => {
                    if (value !== "0") {
                      setOfSessionBillingLabel("");
                      setOfSessionPrePayLabel("");
                      setCurrentValues((prevvalues) => ({
                        ...prevvalues,
                        ofSessionBilling: null,
                        ofSessionPrePay: null,
                      }));
                    } else {
                      setOfSessionBillingLabel(
                        t("input_label_of_sessions_billing"),
                      );
                      setOfSessionPrePayLabel(
                        t("input_label_of_sessions_prepayment"),
                      );
                      setCurrentValues((prevvalues) => ({
                        ...prevvalues,
                        ofSessionBilling: "",
                        ofSessionPrePay: "",
                      }));
                    }
                  }}
                />
              ) : null}
            </div>

            {(currentValues.groupType === "individual" ||
              currentValues.groupType === "group") &&
            currentValues.billingPeriod == "0" ? (
              <>
                <ScheduleInviteInput
                  name={"of-session-billing"}
                  label={ofSessionBillingLabel}
                  value={currentValues.ofSessionBilling || ""}
                  changeValue={(val) =>
                    handleFieldChange("ofSessionBilling", val)
                  }
                  changeLableValue={changeLabelHandler}
                />

                <CustomSelect
                  options={[
                    {
                      label: t("create_invitation_payment_type_prepaid"),
                      value: "0",
                    },
                    {
                      label: t("create_invitation_payment_type_postpaid"),
                      value: "1",
                    },
                  ]}
                  label={t("select_label_payment_type")}
                  defaultValue={currentValues.paymentType}
                  onSelect={(val) => handleFieldChange("paymentType", val)}
                  optionAction={(value) => {
                    if (value !== "0") {
                      setOfSessionPrePayLabel("");
                      setCurrentValues((prevvalues) => ({
                        ...prevvalues,
                        ofSessionPrePay: null,
                      }));
                    } else {
                      setOfSessionPrePayLabel(
                        t("input_label_of_sessions_prepayment"),
                      );
                      setCurrentValues((prevvalues) => ({
                        ...prevvalues,
                        ofSessionPrePay: "",
                      }));
                    }
                  }}
                />

                {currentValues.paymentType === "0" ? (
                  <ScheduleInviteInput
                    name={"of-session-prePay"}
                    label={ofSessionPrePayLabel}
                    changeValue={(val) =>
                      handleFieldChange("ofSessionPrePay", val)
                    }
                    value={currentValues.ofSessionPrePay || ""}
                    changeLableValue={changeLabelHandler}
                  />
                ) : null}
              </>
            ) : null}

            <div className="form-section-header">
              <div>
                <span>{t("location_section_header")}</span>
              </div>
            </div>
            <div className="form-section-divider">
              <ScheduleInviteInput
                name="city"
                label={cityLabel}
                value={currentValues.city || ""}
                changeValue={(val) => handleFieldChange("city", val)}
                changeLableValue={changeLabelHandler}
              />
              <ScheduleInviteInput
                name="district"
                label={districtLabel}
                value={currentValues.district || ""}
                changeValue={(val) => handleFieldChange("district", val)}
                changeLableValue={changeLabelHandler}
              />
              <ScheduleInviteInput
                name="address"
                label={addressLabel}
                value={currentValues.address || ""}
                changeValue={(val) => handleFieldChange("address", val)}
                changeLableValue={changeLabelHandler}
              />
            </div>
          </form>
        </div>
      )}
      <div className="invite-button-container">
        <button id="add-schedule" onClick={saveButtonClick}>
          {t("save_changes")}
        </button>
        <button
          id="cancel"
          onClick={() => {
            setCancelModalToggle("modal-confirm");
          }}
        >
          {t("cancel")}
        </button>
      </div>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default ScheduleEdit;
