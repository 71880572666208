import "./style.scss";

const AuthInput = ({
  type,
  value,
  queryValue,
  placeholder,
  changeValue,
  autoComplete,
  name,
  EyeIcon,
  HiddenIcon,
  changePasswordVisibility,
  disability,
}) => {
  return (
    <div className={`auth-input-container ${type}`}>
      <input
        name={name}
        disabled={disability}
        id={name}
        className="input"
        type={type}
        autoComplete={autoComplete}
        value={disability === false ? value : queryValue}
        placeholder={placeholder}
        onChange={(e) => {
          changeValue(e.target.value);
        }}
      />

      <div
        id="eye"
        onClick={() => {
          changePasswordVisibility("password");
        }}
      >
        {HiddenIcon ? <HiddenIcon /> : null}
      </div>
      <div
        id="hidden"
        onClick={() => {
          changePasswordVisibility("text");
        }}
      >
        {EyeIcon ? <EyeIcon /> : null}
      </div>
    </div>
  );
};

export default AuthInput;
