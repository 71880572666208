import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { jwtDecode } from "jwt-decode";
import { format } from "date-fns";

import ModalWarning from "../../Components/ModalWarning/ModalWarning";
import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import DotsMenu from "../../Components/DotsMenu";
import ScrollableItems from "../../Components/ScrollableItems";
import ScheduleInviteInput from "../../Components/ScheduleInviteInput/ScheduleInviteInput";

import { endDateIndex, monthNames } from "../../store/GlobalVarriables";
import useApi from "../../hooks/useApi";
import { useStatusModal } from "../../context/StatusModalContext";
import { getNext30Days } from "../../helpers/days";

import { ReactComponent as Warning } from "../../static/images/exclamation_sign.svg";
import { ReactComponent as Tick } from "../../static/images/tick_sign.svg";

import { ReactComponent as Tennis } from "../../static/images/consumer_tennis.svg";
import { ReactComponent as Boxing } from "../../static/images/consumer_boxing.svg";
import { ReactComponent as Yoga } from "../../static/images/consumer_yoga.svg";
import { ReactComponent as Gym } from "../../static/images/consumer_gym.svg";
import { ReactComponent as Soccer } from "../../static/images/consumer_soccer.svg";
import { ReactComponent as Basketball } from "../../static/images/consumer_basketball.svg";
import { ReactComponent as Dance } from "../../static/images/consumer_dance.svg";
import { ReactComponent as Other } from "../../static/images/consumer_other.svg";

import "./style.scss";

const ScheduleConsumer = () => {
  const { post, patch, get, loading } = useApi();
  const { t } = useTranslation();

  const { showStatusModal } = useStatusModal();
  const eventsRefs = useRef([]);

  const [subscriptionEventData, setSubscriptionEventData] = useState([]);
  const [dataForDays, setDataForDays] = useState([]);

  const [days, setDays] = useState([]);
  const [activeDay, setActiveDay] = useState(0);
  const [scrollByCode, setScrollByCode] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [warningModalToggle, setWarningModalToggle] = useState("");
  const [warningModalText, setWarningModalText] = useState("");
  const [confirmModalToggle, setConfirmModalToggle] = useState("");
  const [detectModal, setDetectModal] = useState(0);

  const [selectedItem, setSelectedItem] = useState(null);

  const [inputNameValue, setInputNameValue] = useState([]);

  let targetList = [];

  const menuItems = [
    {
      text: t("cancel"),
      onClick: () => {
        if (selectedItem?.customerCanCancel === false) {
          return;
        }
        setDetectModal(1);
        setConfirmModalToggle("modal-confirm");
      },
      disabled: selectedItem?.customerCanCancel === true ? false : true,
    },

    {
      text: t("reschedule"),
      onClick: () => {},
      disabled: true,
    },
    {
      text: t("rename"),
      onClick: () => {
        setDetectModal(2);
        setConfirmModalToggle("modal-confirm");
      },
    },
  ];

  const RenderActivities = ({ activities }) => {
    const activityIcons = {
      tennis: <Tennis />,
      boxing: <Boxing />,
      yoga: <Yoga />,
      soccer: <Soccer />,
      basketball: <Basketball />,
      dance: <Dance />,
      "Personal Trainer": <Gym />,
      other: <Other />,
    };

    return <>{activityIcons[activities] || activityIcons["other"]}</>;
  };

  const fetchInvitation = async () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));

    const encodedToken = jwtDecode(ps10005000);

    try {
      const data = await get(
        `/Invitation?PhoneNumber=${encodedToken.sub}&Email=${encodeURIComponent(encodedToken.email)}&Status=Pending`,
      );
      if (data.length) {
        setWarningModalToggle("modal-warning");
        setWarningModalText(t("consumer_schedules_pending_warning"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchSchedule(token, buid) {
    try {
      const data = await get(
        `/BusinessAccount/${buid}/Subscriptions?oneTime=true`,
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchEvents(token, buid) {
    const today = new Date().toJSON();
    try {
      const data = await get(
        `/ApplicationEvent?EventDate=${today}&EventStatuses=Cancelled&EventStatuses=Rescheduled&EventStatuses=Scheduled&ConsumerBusinessAccountId=${buid}`,
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  function convertTimeToObject(timeString) {
    const [hour, minute, second] = timeString.split(":");
    return { hour, minute, second };
  }

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const random = Math.random().toString(36).substring(2, 8);
    return `${timestamp}${random}`;
  }

  async function getScheduledEvents() {
    try {
      const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
      const encodedToken = jwtDecode(ps10005000);

      const subscription = await fetchSchedule(ps10005000, encodedToken.buid);
      const events = await fetchEvents(ps10005000, encodedToken.buid);

      margeSchedule(subscription, events);
    } catch (error) {
      console.error("Error displaying scheduled events:", error);
    }
  }

  function getAllDatesForWeekdayInRange(startDate, endDate, targetDayOfWeek) {
    let currentDay = startDate.getDay();
    let daysToAdd = targetDayOfWeek - currentDay;
    if (daysToAdd < 0) {
      daysToAdd += 7;
    }
    let currentDate = new Date(startDate.getTime());
    currentDate.setDate(startDate.getDate() + daysToAdd);
    const dates = [];
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate.getTime())); // Clone the date to avoid reference issues
      currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
    }
    return dates;
  }

  function getDayOfWeek(day) {
    switch (day) {
      case "Monday":
        return 1;
      case "Tuesday":
        return 2;
      case "Wednesday":
        return 3;
      case "Thursday":
        return 4;
      case "Friday":
        return 5;
      case "Saturday":
        return 6;
      case "Sunday":
        return 0;
      default:
        throw Error;
    }
  }

  const manageEvent = (eventData, targetList) => {
    eventData
      .filter(
        (e) => e.eventStatus === "Rescheduled" || e.eventStatus === "Cancelled",
      )
      .forEach((item) => {
        targetList
          .filter(
            (x) =>
              x.OriginalScheduledDate.toDateString() ===
                item.originalEventDate.toDateString() &&
              x.data.some(
                (i) =>
                  i.OriginalScheduledTimeOnly.hour ===
                    ("0" + item.originalEventDate.getHours()).slice(-2) &&
                  i.OriginalScheduledTimeOnly.minute ===
                    ("0" + item.originalEventDate.getMinutes()).slice(-2) &&
                  i.scheduleId === item.scheduleId,
              ),
          )
          .forEach((i) => {
            // Updating TargetObject NewScheduledDate with EventDate
            i.NewScheduledDate = item.eventDate;
            i.eventId = item.id;

            // Updating TargetObject time and status from fetched event
            i.data.forEach((j) => {
              j.NewScheduledTimeOnly = {
                hour: ("0" + item.eventDate.getHours()).slice(-2),
                minute: ("0" + item.eventDate.getMinutes()).slice(-2),
              };
              j.Status = item.eventStatus;
            });
          });
      });

    let dataForDays = [];
    let mergedSubscriptionData = [];

    targetList.forEach((item) => {
      let existingItemIndex = mergedSubscriptionData.findIndex(
        (mergedItem) =>
          mergedItem.NewScheduledDate.getFullYear() ===
            item.NewScheduledDate.getFullYear() &&
          mergedItem.NewScheduledDate.getMonth() ===
            item.NewScheduledDate.getMonth() &&
          mergedItem.NewScheduledDate.getDate() ===
            item.NewScheduledDate.getDate(),
      );

      if (existingItemIndex !== -1) {
        mergedSubscriptionData[existingItemIndex].data = mergedSubscriptionData[
          existingItemIndex
        ].data.concat(item.data);
      } else {
        mergedSubscriptionData.push(item);
      }
    });
    mergedSubscriptionData.sort(
      (a, b) => a.NewScheduledDate - b.NewScheduledDate,
    );

    const newSchedule = {};
    // Iterate over the schedule array
    mergedSubscriptionData.forEach((item) => {
      if (item.data[0].Status !== "Cancelled") {
        dataForDays.push(item.data[0]);
        // Check if the MonthName already exists in newSchedule
        if (!newSchedule[item.MonthName]) {
          // If not, create a new entry with MonthName and MonthData as an array
          newSchedule[item.MonthName] = {
            MonthName: item.MonthName,
            MonthData: [],
          };
        }

        // Push the current item into the corresponding MonthData array
        newSchedule[item.MonthName].MonthData.push({
          eventId: item.eventId || null,
          WeekDay: item.WeekDay,
          NewScheduledDate: item.NewScheduledDate,
          OriginalScheduledDate: item.OriginalScheduledDate,
          data: item.data,
        });
      }
    });

    // Convert the object into an array
    const finalData = Object.values(newSchedule);
    const days = getNext30Days(dataForDays);
    setDataForDays(dataForDays);
    const activeDayIndex = days.findIndex((e) => e.hasEvents);
    setActiveDay(activeDayIndex);
    setDays(days);
    setSubscriptionEventData(finalData);
  };

  const margeSchedule = (subscriptionData, eventData) => {
    subscriptionData.map((item) => {
      item.scheduleDays.forEach((day) => {
        day.timeOnly = convertTimeToObject(day.timeOnly);
      });
    });

    eventData.map((item) => {
      item.eventDate = new Date(item.eventDate);
      item.originalEventDate = new Date(item.originalEventDate);
    });

    // const startDate = new Date(2024, 4, 1); // Note: Months in JavaScript are 0-based, so 3 represents April
    // const endDate = new Date(2024, 4, 20);
    const startDate = new Date();
    const endDate = new Date(
      new Date().setMonth(startDate.getMonth() + endDateIndex),
    );

    subscriptionData.forEach((subscription) => {
      subscription.scheduleDays.forEach((day) => {
        const efectiveFrom = new Date(subscription.scheduleEffectiveFrom);
        const validStartDate =
          efectiveFrom >= startDate ? efectiveFrom : startDate;

        validStartDate.setHours(day.timeOnly.hour);
        validStartDate.setMinutes(day.timeOnly.minute);
        validStartDate.setSeconds(day.timeOnly.second);
        var dates = getAllDatesForWeekdayInRange(
          validStartDate,
          endDate,
          getDayOfWeek(day.dayOfWeek),
        );
        dates.forEach((x) => {
          let tempObject = {
            MonthName: monthNames[x.getMonth()],
            WeekDay: day.dayOfWeek,
            OriginalScheduledDate: x,
            NewScheduledDate: x,
            data: [
              {
                subId: subscription.id,
                dayId: day.id,
                subscriptionId: subscription.id,
                scheduleId: subscription.scheduleId,
                OriginalScheduledDate: x,
                NewScheduledDate: x,
                OriginalScheduledTimeOnly: day.timeOnly,
                ScheduleDescription: subscription.description,
                Status: "Scheduled",
                NewScheduledTimeOnly: day.timeOnly,
                guid: generateUniqueId(),
                customerCanCancel: subscription.allowedCancellation,
                scheduleActivityName: subscription.scheduleActivityName || "",
              },
            ],
          };
          targetList.push(tempObject);
        });
      });
    });

    const oneTimeEvents = eventData.filter(
      (e) => e.eventStatus === "Scheduled",
    );

    oneTimeEvents.forEach((event) => {
      const oneTimeSubscription = subscriptionData.find(
        (elem) => elem.scheduleId === event.scheduleId,
      );

      let oneTimeData = {
        MonthName: monthNames[event.eventDate.getMonth()],
        WeekDay: event.eventDate.toLocaleDateString("en-US", {
          weekday: "long",
        }),
        OriginalScheduledDate: event.originalEventDate,
        NewScheduledDate: event.eventDate,

        data: [
          {
            eventId: event.id,
            dayId: event.scheduleDayId,
            subscriptionId: oneTimeSubscription?.id,
            scheduleId: event.scheduleId,
            OriginalScheduledDate: event.originalEventDate,
            NewScheduledDate: event.eventDate,
            NewScheduledTimeOnly: {
              hour: event.eventDate.getHours().toString().padStart(2, "0"),
              minute: event.eventDate.getMinutes().toString().padStart(2, "0"),
              second: event.eventDate.getSeconds().toString().padStart(2, "0"),
            },
            OriginalScheduledTimeOnly: {
              hour: event.originalEventDate
                .getHours()
                .toString()
                .padStart(2, "0"),
              minute: event.originalEventDate
                .getMinutes()
                .toString()
                .padStart(2, "0"),
              second: event.originalEventDate
                .getSeconds()
                .toString()
                .padStart(2, "0"),
            },
            ScheduleDescription: oneTimeSubscription?.description,
            Status: "Scheduled",
            guid: generateUniqueId(),
          },
        ],
      };
      targetList.push(oneTimeData);
    });

    manageEvent(eventData, targetList);
  };

  useEffect(() => {
    try {
      fetchInvitation();
      getScheduledEvents();
    } catch (err) {
      console.error("Error:", err);
    }
  }, [warningModalToggle]);

  const handleToggleMenu = (item) => {
    setSelectedItem((prevItem) => (prevItem?.guid === item.guid ? null : item));
    setInputNameValue(item.ScheduleDescription);
  };

  const formatDate = (date) => {
    const localDate = new Date(date);
    localDate.setHours(localDate.getHours() + 4);
    return localDate.toISOString();
  };

  const getTimeWithoutSeconds = (dateString) => {
    const date = new Date(dateString);
    const options = { hour: "2-digit", minute: "2-digit", hour12: false };
    const timeWithoutSeconds = date.toLocaleTimeString("en-US", options);
    return timeWithoutSeconds;
  };

  const cancelShchedule = async () => {
    const method = selectedItem.eventId ? patch : post;
    const dataToPass = selectedItem.eventId
      ? {
          id: selectedItem.eventId,
          eventStatus: "Cancelled",
        }
      : {
          scheduleDayId: selectedItem.dayId,
          eventTime: formatDate(selectedItem.NewScheduledDate),
          originalEventTime: formatDate(selectedItem.OriginalScheduledDate),
          eventStatus: "Cancelled",
          scheduleId: selectedItem.scheduleId,
        };

    try {
      const data = await method("/ApplicationEvent", dataToPass);
      if (data) {
        setSelectedItem(null);
        const successMessage = t("consumer_schedules_cancel_success");
        showStatusModal(successMessage);
        getScheduledEvents();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renameSubscription = async () => {
    try {
      const data = await patch("/Subscription", {
        id: selectedItem.subId,
        description: inputNameValue,
      });
      setWarningModalToggle("modal-warning");
      setWarningModalText(t("consumer_schedules_renamed_warning"));
    } catch (err) {}
  };

  const handleSelectDay = (index, day, hasEvents) => {
    if (!hasEvents) {
      return;
    }
    const formattedEventDates = dataForDays?.map((elem) =>
      format(elem.NewScheduledDate, "EEE dd"),
    );
    const indexInEvents = formattedEventDates.indexOf(day);
    const activeElement = eventsRefs.current[indexInEvents];
    if (activeElement) {
      const rect = activeElement.getBoundingClientRect();
      const absoluteElementTop = rect.top + window.scrollY;
      setScrollByCode(true);
      window.scrollTo({
        top: absoluteElementTop - 140,
        behavior: "smooth",
      });

      setTimeout(() => {
        setScrollByCode(false);
      }, 500);
    }
    setActiveDay(index);
  };

  const handleScroll = () => {
    if (scrollByCode) {
      return;
    }
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const minusPixels = scrollTop > lastScrollTop ? 30 : -5;
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    let cumulativeHeight = 0;
    let currentDay = 0;

    for (let i = 0; i < eventsRefs.current.length; i++) {
      if (
        window.scrollY <
        cumulativeHeight + eventsRefs.current[i].offsetHeight + minusPixels
      ) {
        currentDay = i;
        break;
      }
      cumulativeHeight += eventsRefs.current[i].offsetHeight;
    }
    const filteredDays = days.filter((day) => day.hasEvents);
    if (filteredDays.length > 0) {
      const nextIndex = days.findIndex(
        (elem) => elem.date === filteredDays[currentDay].date,
      );
      setActiveDay(nextIndex);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollByCode, days, lastScrollTop]);

  let refsIndex = 0;
  const scrollItems = days.map((item) => ({
    ...item,
    subText: t(item.dayName).slice(0, 3),
  }));

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ModalWarning
        warningText={warningModalText}
        modalSubText={
          detectModal === 2 ? t("consumer_schedules_renamed_sub_warning") : ""
        }
        buttonText={detectModal === 2 ? t("go_main_page") : t("go_invitations")}
        changeModal={setWarningModalToggle}
        modalValue={warningModalToggle}
        Image={detectModal === 2 ? Tick : Warning}
      />

      <ModalConfirmation
        modalText={
          detectModal === 1
            ? t("consumer_schedule_cancel_confirm_text")
            : t("consumer_schedule_rename_confirm_text")
        }
        modalSubText={
          detectModal === 1
            ? t("consumer_schedule_cancel_confirm_subtext", {
                time: getTimeWithoutSeconds(selectedItem?.NewScheduledDate),
              })
            : t("consumer_schedule_rename_confirm_subtext")
        }
        inputModal={
          detectModal === 2 ? (
            <ScheduleInviteInput
              label={t("input_label_rename_subscription")}
              value={inputNameValue}
              changeValue={setInputNameValue}
            />
          ) : null
        }
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        confirm={detectModal === 1 ? cancelShchedule : renameSubscription}
        cancel={() => {
          setSelectedItem(null);
        }}
      />
      <div className="consumer-container">
        <ScrollableItems
          items={scrollItems}
          activeItem={activeDay}
          selectItem={handleSelectDay}
        />
        {subscriptionEventData.length === 0 ? (
          <div className="empty-schedule">
            <h1>{t("consumer_schedules_empty")}</h1>
          </div>
        ) : (
          subscriptionEventData.map((day, Dayindex) => {
            return (
              <div className="schedule-list-container" key={Dayindex}>
                <div className="schedule-day">
                  <span>{t(day.MonthName.toLocaleLowerCase())}</span>
                </div>
                <ul>
                  {day.MonthData.map((item, itemIndex) => {
                    return (
                      <li
                        key={itemIndex}
                        ref={(el) => (eventsRefs.current[refsIndex++] = el)}
                      >
                        {item.data
                          .filter((stat) => stat.Status !== "Cancelled")
                          .map((elem, dIndex) => {
                            return (
                              <div
                                className="sub-single-schedule-container"
                                key={dIndex}
                              >
                                <div className="single-schedule-description">
                                  <span>{elem.ScheduleDescription}</span>
                                </div>
                                <div className="sub-schedule-day-container">
                                  <div className="sub-schedule-day">
                                    <span>
                                      {item.NewScheduledDate.getDate()}
                                    </span>
                                  </div>
                                  <div className="schedule-line"></div>
                                </div>
                                <div className="single-schedule">
                                  <div className="single-schedule-time">
                                    <div className="session-icon">
                                      <RenderActivities
                                        activities={elem.scheduleActivityName}
                                      />
                                    </div>
                                    <span>
                                      {elem.NewScheduledTimeOnly.hour}:
                                      {elem.NewScheduledTimeOnly.minute}
                                    </span>
                                  </div>
                                  <div className="single-schedule-icons">
                                    <div>
                                      <DotsMenu
                                        menuItems={menuItems}
                                        isMenuOpen={
                                          selectedItem?.guid === elem.guid &&
                                          !confirmModalToggle
                                        }
                                        toggleMenu={() =>
                                          handleToggleMenu(elem)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default React.memo(ScheduleConsumer);
