import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as SettingsIcon } from "../../static/images/settings_icon.svg";

import "./style.scss";

const DotsMenu = ({ isMenuOpen, toggleMenu, menuItems }) => {
  const menuRef = useRef(null);

  const [openAbove, setOpenAbove] = useState(false);

  useEffect(() => {
    const menuRect = menuRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    if (menuRect.bottom + (menuItems.length + 1) * 40 > viewportHeight) {
      setOpenAbove(true);
    } else {
      setOpenAbove(false);
    }

    const handleClickOutside = (event) => {
      const modalBody = document.querySelector(".modal-body");

      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        (!modalBody || !modalBody.contains(event.target))
      ) {
        toggleMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, toggleMenu]);

  return (
    <div className="menu-wrapper" ref={menuRef}>
      <button className="menu-toggle" onClick={toggleMenu}>
        <SettingsIcon />
      </button>
      {isMenuOpen && (
        <div className={`menu ${openAbove ? "menu-above" : "menu-below"}`}>
          {menuItems.map((item, index) => (
            <div
              className={item.disabled ? "menu-item disabled" : "menu-item"}
              key={index}
              onClick={item.onClick}
            >
              {item.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DotsMenu;
