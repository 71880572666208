import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

const ScrollableItems = ({ items, activeItem, selectItem }) => {
  const itemsRef = useRef(null);
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    if (itemsRef.current) {
      const activeElement = itemsRef.current.children[activeItem];
      const containerRect = itemsRef.current.getBoundingClientRect();
      const elementRect = activeElement?.getBoundingClientRect();

      if (
        elementRect?.left < containerRect.left ||
        elementRect?.right > containerRect.right
      ) {
        const scrollAmount =
          elementRect?.left -
          containerRect?.left -
          (containerRect?.width / 2 - elementRect?.width / 2);
        itemsRef.current.scrollLeft += scrollAmount;
      }
    }
  }, [activeItem]);

  useEffect(() => {
    const handleMouseUp = () => setIsDragging(false);
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      const x = e.clientX - startPosition;
      itemsRef.current.scrollLeft = scrollLeft - x;
    };

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragging, startPosition, scrollLeft]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition(e.clientX);
    setScrollLeft(itemsRef.current.scrollLeft);
  };
  return (
    <div
      className={
        items.length < 15 ? "itemCards-wrapper center" : "itemCards-wrapper"
      }
      ref={itemsRef}
      onMouseDown={handleMouseDown}
    >
      {items.map((item, index) => (
        <button
          key={index}
          className={`itemCard ${index === activeItem ? "active" : ""} ${!item.hasEvents ? "disabled" : ""}`}
          onClick={() => selectItem(index, item.date, item.hasEvents)}
        >
          <span className="itemSubtext">{item.subText}</span>
          <span className="itemText">{item.mainText}</span>
        </button>
      ))}
    </div>
  );
};

export default ScrollableItems;
