import { useTranslation } from "react-i18next";

import PhoneNumberToDial from "../PhoneNumberToDial";
import { monthNames } from "../../store/GlobalVarriables";

import "./style.scss";

const ConfirmOneTimeInvitation = ({
  item,
  setConfirmModalToggle,
  setModalText,
  setSingleInv,
  setInputNameValue,
}) => {
  const { t } = useTranslation();

  const formatDate = (date) => {
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];

    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    const weekdayIndex = date.getDay();
    const time = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return [formattedDate, time, weekDays[weekdayIndex]];
  };
  const formattedDate = formatDate(new Date(item.eventDateTime));
  return (
    <li key={item.id} className="invitation-container-li">
      <div className="invitation-list-container">
        <div className="invitation-from">
          <span>{t("invitation_from")}</span>
        </div>
        <div className="invitation-name">
          <h2>{item.providerName}</h2>
        </div>

        <div className="provider-phone">
          <PhoneNumberToDial number={item.providerPhoneNumber} />
        </div>

        <div className="date-and-time">
          <b>{formattedDate[0]}</b>
          <p>
            {formattedDate[1]} - {formattedDate[2]}
          </p>
        </div>

        <div className="financial-details">
          <ul>
            <li>{t("one_time_invitation")}</li>
            <li>
              <span>
                {`${t("price")} ${item?.financialDetails?.pricingType === "Monthly" ? t("per_month") : t("per_session")} ${t("is_price", { price: item?.financialDetails.price })}`}
              </span>
            </li>
            <li>{t("pay_after_session")}</li>
          </ul>
        </div>

        <div className="invitation-buttons">
          <div id="invitation-accept">
            <button
              onClick={(e) => {
                setConfirmModalToggle("modal-confirm");
                setModalText(t("activate_subscription"));
                setSingleInv(item);
                setInputNameValue(item.providerName);
              }}
            >
              {t("accept")}
            </button>
          </div>
          <div id="invitation-reject">
            <button
              onClick={(e) => {
                setConfirmModalToggle("modal-confirm");
                setModalText(t("cancel_subscription"));
                setSingleInv(item);
              }}
            >
              {t("reject")}
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ConfirmOneTimeInvitation;
