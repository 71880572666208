import "./style.scss";

const ScheduleInviteInput = ({
  name,
  changeValue,
  autoComplete,
  label,
  changeLableValue,
  value,
  disabled = false,
}) => {
  return (
    <div className="schedule-invite-input">
      <input
        type={"text"}
        name={name}
        autoComplete={autoComplete}
        id={name}
        value={value}
        onChange={(e) => {
          changeValue(e.target.value);
          if (changeLableValue) {
            changeLableValue(name, e.target.value);
          }
        }}
        disabled={disabled}
      />
      <label
        htmlFor={name}
        style={
          label.includes("!") || label.includes("required")
            ? { color: "red" }
            : { color: "rgb(143, 142, 142)" }
        }
      >
        {label}
      </label>
    </div>
  );
};

export default ScheduleInviteInput;

// const [startDate, setStartDate] = useState(null);
// return (
//   <DatePicker
//     selected={startDate}
//     onChange={(date) => setStartDate(date)}
//     minDate={subDays(new Date(), 5)}
//     placeholderText="Select a date after 5 days ago"
//       dateFormat="d MMMM yyyy"
//       dateFormat="MMMM d, yyyy h:mm aa"
//   />
// );

// import * as React from 'react';
// import dayjs from 'dayjs';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
// import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
// import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

// export default function ResponsiveDateTimePickers() {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer
//         components={[
//           'DateTimePicker',
//           'MobileDateTimePicker',
//           'DesktopDateTimePicker',
//           'StaticDateTimePicker',
//         ]}
//       >
//         <DemoItem label="Desktop variant">
//           <DesktopDateTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
//         </DemoItem>
//         <DemoItem label="Mobile variant">
//           <MobileDateTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
//         </DemoItem>
//         <DemoItem label="Responsive variant">
//           <DateTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
//         </DemoItem>
//         <DemoItem label="Static variant">
//           <StaticDateTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
//         </DemoItem>
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }
