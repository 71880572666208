import { useNavigate, useLocation } from "react-router-dom";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../hooks/useApi";

const registrationDataContext = createContext({});

export const RegistrationDataProvider = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [register_password, setRegister_password] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [register_email, setRegister_email] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [activityName, setActivityName] = useState("");

  const [registerValidation, setRegisterValidation] = useState("");
  const [successLoader, setSuccessLoader] = useState(false);

  const [providerRegistrationSteps, setProviderRegistrationSteps] = useState(0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { post, loading } = useApi();
  const { pathname } = useLocation();

  let upperCaseLetters = /[A-Z]/g;
  let number = /[0-9]/g;
  let specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  let emailValidation = /@./;

  const FetchRegister = () => {
    return new Promise(async (resolve) => {
      if (
        firstName.trim() === "" ||
        lastName.trim() === "" ||
        register_email.trim() === "" ||
        register_password === "" ||
        phoneNumber.trim() === "" ||
        confirmPassword === ""
      ) {
        setRegisterValidation(t("error_fill_all"));
        return;
      }

      if (phoneNumber.length !== 9) {
        setRegisterValidation(t("error_incorrect_phone"));
        return;
      }

      if (!register_email.match(emailValidation)) {
        setRegisterValidation(t("error_incorrect_email"));
        return;
      }

      if (register_password !== confirmPassword) {
        setRegisterValidation(t("error_password_confirm_failed"));
        return;
      }

      if (!register_password.match(upperCaseLetters)) {
        setRegisterValidation(t("error_password_contains_uppercase"));
        return;
      }
      if (!register_password.match(specialChars)) {
        setRegisterValidation(t("error_password_contains_special"));
        return;
      }
      if (!register_password.match(number)) {
        setRegisterValidation(t("error_password_contains_digit"));
        return;
      }
      if (register_password.length < 8) {
        setRegisterValidation(t("error_password_length"));
        return;
      }

      try {
        setRegisterValidation("");

        const commonData = {
          phoneNumber: phoneNumber,
          password: register_password,
          confirmPassword: confirmPassword,
          email: register_email,
          firstName: firstName,
          lastName: lastName,
        };

        const registerData = await post("/User", {
          ...commonData,
          userRole:
            pathname.includes("consumer-registration") || pathname === "/signUp"
              ? "Consumer"
              : "Provider",
          ...(pathname.includes("provider-registration") && {
            city: city,
            district: district,
            address: address,
            activityName: activityName,
          }),
        });

        if (!registerData.succeeded === true) {
          setRegisterValidation(registerData.errors[0].description);
          setSuccessLoader(false);
          return;
        }

        setSuccessLoader(true);

        const timeOut = setTimeout(() => {
          setSuccessLoader(false);

          clearTimeout(timeOut);
        }, [3000]);
        try {
          const loginData = await post("/User/login", {
            phoneNumber: phoneNumber,
            password: register_password,
            rememberLogin: true,
          });

          localStorage.setItem("ps10005000", JSON.stringify(loginData.token));
          localStorage.setItem(
            "ps10004000",
            JSON.stringify(loginData.refreshToken),
          );

          navigate("/sessions");
        } catch (error) {}

        // setFirstName("");
        // setLastName("");
        // setRegister_email("");
        // setPhoneNumber("");
        // setRegister_password("");
        // setConfirmPassword("");
      } catch (error) {
        setRegisterValidation(t("error_something_wrong"));
        // setFirstName("");
        // setLastName("");
        // setRegister_email("");
        // setPhoneNumber("");
        // setRegister_password("");
        // setConfirmPassword("");
      }
      resolve();
    });
  };

  return (
    <registrationDataContext.Provider
      value={{
        firstName,
        lastName,
        phoneNumber,
        register_password,
        confirmPassword,
        register_email,
        registerValidation,
        city,
        district,
        address,
        activityName,
        providerRegistrationSteps,

        changeProviderRegistrationSteps: setProviderRegistrationSteps,
        changeFirstName: setFirstName,
        changeLastName: setLastName,
        changePhoneNumber: setPhoneNumber,
        changeRegister_password: setRegister_password,
        changeConfirmPassword: setConfirmPassword,
        changeRegister_email: setRegister_email,
        changeRegisterValidation: setRegisterValidation,
        changeCity: setCity,
        changeDistrict: setDistrict,
        changeAddress: setAddress,
        changeActivityName: setActivityName,

        registrationLoader: loading,
        successLoader,
        FetchRegister,
      }}
    >
      {children}
    </registrationDataContext.Provider>
  );
};

const useRegistration = () => useContext(registrationDataContext);

export default useRegistration;
