import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { jwtDecode } from "jwt-decode";

import useApi from "../../hooks/useApi";

import ModalConfirmation from "../../Components/ModalConfirmation/ModalConfirmation";
import ModalWarning from "../../Components/ModalWarning/ModalWarning";
import ScheduleInviteInput from "../../Components/ScheduleInviteInput/ScheduleInviteInput";
import ConfirmInvitation from "../../Components/ConfirmInvitation/ConfirmInvitation";
import ConfirmOneTimeInvitation from "../../Components/ConfirmOneTimeInvitation/ConfirmOneTimeInvitation";

import { ReactComponent as Tick } from "../../static/images/tick_sign.svg";

import "./style.scss";

const Invitation = () => {
  const { put, get, loading } = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [invitationData, setInvitationData] = useState([]);

  const [confirmModalToggle, setConfirmModalToggle] = useState("");
  const [warningModalToggle, setWarningModalToggle] = useState("");
  const [modalText, setModalText] = useState("");
  const [singleInv, setSingleInv] = useState({});
  const [cardAction, setCardAction] = useState("");

  const [inputNameValue, setInputNameValue] = useState("");

  const fetchInvitation = async () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));

    if (ps10005000) {
      const encodedToken = jwtDecode(ps10005000);

      try {
        const data = await get(
          `/Invitation?PhoneNumber=${encodedToken.sub}&Email=${encodeURIComponent(encodedToken.email)}&Status=Pending`,
        );

        setInvitationData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const cancelInvitation = async () => {
    try {
      const data = await put(`/Invitation`, {
        id: singleInv.id,
        operation: "Cancel",
      });
      await fetchInvitation();

      setModalText(t("thank_you"));
      setWarningModalToggle("modal-warning");
    } catch (error) {
      console.log(error);
    }
  };

  const activateInvitation = async () => {
    try {
      const data = await put(`/Invitation`, {
        id: singleInv.id,
        operation: "Activate",
        subscriptionName: inputNameValue,
      });

      await fetchInvitation();

      setModalText(t("subscription_activation"));
      setWarningModalToggle("modal-warning");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      fetchInvitation();
    } catch (err) {
      console.error("Error:", err);
    }
  }, []);

  return (
    <div className="invitation-container">
      <ModalConfirmation
        changeModal={setConfirmModalToggle}
        modalValue={confirmModalToggle}
        modalText={modalText}
        confirm={
          cardAction === "cancel" ? cancelInvitation : activateInvitation
        }
        inputModal={
          cardAction === "cancel" ? null : (
            <ScheduleInviteInput
              label={t("input_label_rename_subscription")}
              value={inputNameValue}
              changeValue={setInputNameValue}
            />
          )
        }
      />
      <ModalWarning
        changeModal={setWarningModalToggle}
        modalValue={warningModalToggle}
        warningText={modalText}
        buttonText={invitationData.length < 1 ? t("go_main_page") : t("close")}
        Image={Tick}
      />

      {invitationData.length ? (
        <ul className="invitation-container-ul">
          {invitationData.map((item) => {
            return item.type === "OneTime" ? (
              <ConfirmOneTimeInvitation
                key={item.id}
                item={item}
                setConfirmModalToggle={setConfirmModalToggle}
                setModalText={setModalText}
                setSingleInv={setSingleInv}
                setInputNameValue={setInputNameValue}
              />
            ) : (
              <ConfirmInvitation
                key={item.id}
                item={item}
                setConfirmModalToggle={setConfirmModalToggle}
                setModalText={setModalText}
                setSingleInv={setSingleInv}
                setInputNameValue={setInputNameValue}
                setCardAction={setCardAction}
              />
            );
          })}
        </ul>
      ) : (
        <div className="empty-invitation">
          <h2> {t("invitation_empty")}</h2>
        </div>
      )}
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default Invitation;
