import React, { useState, useRef, useEffect } from "react";
import "./style.scss";

const CustomSelect = ({
  options,
  label,
  disabled,
  onSelect,
  optionAction,
  defaultValue = null,
}) => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // select default value
  useEffect(() => {
    if (defaultValue) {
      const option = options.find((item) => item.value === defaultValue);
      setSelected(option);
    }
  }, [defaultValue]);

  // Handle closing dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    setSelected(option);
    setIsOpen(false);

    onSelect(option.value);
  };

  return (
    <div
      className={`custom-select ${disabled ? "disabled" : ""}`}
      ref={dropdownRef}
    >
      <div
        className="selected-option"
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {selected ? selected.label : options[0].label}
      </div>
      {isOpen && (
        <ul className="select-options">
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                handleSelect(option);

                if (optionAction) {
                  optionAction(option.value);
                }
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      {label && <div className="custom-select-label">{label}</div>}
    </div>
  );
};

export default CustomSelect;
