import { useState } from "react";

export const endDateIndex = 1;

export function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.random().toString(36).substring(2, 8);
  return `${timestamp}${random}`;
}

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export let numberValidation = /^[1-9]\d*(\.\d+)?$|^0(\.\d+)?$/;
export let emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// for invitatition modal toggle

let modalState = "passive";
let listeners = [];

const setModalState = (newState) => {
  modalState = newState;
  listeners.forEach((listener) => listener(modalState));
};

export const useModalStore = () => {
  const [state, setState] = useState(modalState);

  const setGlobalState = (newState) => {
    setModalState(newState);
  };

  listeners.push(setState);

  return [state, setGlobalState];
};
