import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

import {
  featuresConsumer,
  featuresProvider,
  TOOLTIP_STORAGE_KEY_PROVIDER,
  TOOLTIP_STORAGE_KEY_CONSUMER,
} from "./fetures";

import "./style.css";

const FeatureTooltip = ({ menuState, togleMenu }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
  const userRole = ps10005000 ? jwtDecode(ps10005000).roles : null;

  const { t } = useTranslation();

  const features =
    userRole === "Provider" ? featuresProvider : featuresConsumer;
  const tooltipKey =
    userRole === "Provider"
      ? TOOLTIP_STORAGE_KEY_PROVIDER
      : TOOLTIP_STORAGE_KEY_CONSUMER;
  const navigate = useNavigate();

  useEffect(() => {
    const hasSeenTooltip = localStorage.getItem(tooltipKey);
    if (!hasSeenTooltip && ps10005000) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [ps10005000]);

  const handleNext = () => {
    const currentFeature = features[currentFeatureIndex];
    if (features[currentFeatureIndex].steps[currentStep - 1].redirectionUrl) {
      navigate(
        features[currentFeatureIndex].steps[currentStep - 1].redirectionUrl,
      );
    }

    if (features[currentFeatureIndex].steps[currentStep]?.isToggleMenu) {
      togleMenu();
    }

    if (currentStep < currentFeature.steps.length) {
      setCurrentStep((prev) => prev + 1);
    } else if (currentFeatureIndex < features.length - 1) {
      setCurrentFeatureIndex((prev) => prev + 1);
      setCurrentStep(1);
    }
  };

  const handleDismiss = () => {
    if (currentFeatureIndex === 0) {
      setShowTooltip(false);
      localStorage.setItem(tooltipKey, "true");
    } else {
      if (currentFeatureIndex < features.length - 1) {
        setCurrentFeatureIndex((prev) => prev + 1);
        setCurrentStep(1);
      } else {
        setShowTooltip(false);
        localStorage.setItem(tooltipKey, "true");
      }

      if (features[currentFeatureIndex].steps[currentStep - 1].redirectionUrl) {
        navigate(
          features[currentFeatureIndex].steps[currentStep - 1].redirectionUrl,
        );
      }

      if (menuState === "active") {
        togleMenu();
      }
    }
  };

  if (!showTooltip || features.length === 0) return null;

  return (
    <div className="modal-overlay">
      <div
        className={
          features[currentFeatureIndex].steps[currentStep - 1].isCenter
            ? "modal-body center"
            : "modal-body"
        }
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="tooltip"
          style={{
            top: features[currentFeatureIndex].steps[currentStep - 1].position
              ?.top,
            bottom:
              features[currentFeatureIndex].steps[currentStep - 1].position
                ?.bottom,
            left: features[currentFeatureIndex].steps[currentStep - 1].position
              ?.left,
            right:
              features[currentFeatureIndex].steps[currentStep - 1].position
                ?.right,
          }}
        >
          {features[currentFeatureIndex].steps[currentStep - 1]
            .arrowPosition === "left" && <div className="triangle-left"></div>}
          {features[currentFeatureIndex].steps[currentStep - 1]
            .arrowPosition === "right" && (
            <div className="triangle-right"></div>
          )}
          {features[currentFeatureIndex].steps[currentStep - 1]
            .arrowPosition === "top" && <div className="triangle-top"></div>}
          {features[currentFeatureIndex].steps[currentStep - 1]
            .arrowPosition === "bottom" && (
            <div className="triangle-bottom"></div>
          )}
          <div className="tooltip-content">
            {currentFeatureIndex === 0 ? (
              <div>
                <p>{features[currentFeatureIndex].name}</p>
                <ul>
                  {features[currentFeatureIndex].steps[0].content.map(
                    (item, index) => (
                      <li key={`${index}-${item}`}>{item}</li>
                    ),
                  )}
                </ul>
                <p>{t("next_for_details")}</p>
              </div>
            ) : (
              <p>{`${features[currentFeatureIndex].name} ${features[currentFeatureIndex].steps[currentStep - 1].content}`}</p>
            )}
          </div>
          {currentFeatureIndex === 0 ? (
            <div className="tooltip-buttons">
              <button onClick={handleDismiss}>{t("dismiss")}</button>
              <button onClick={handleNext}>{t("next")}</button>
            </div>
          ) : (
            <div className="tooltip-buttons">
              <button onClick={handleDismiss}>
                {currentStep === features[currentFeatureIndex].steps.length
                  ? t("got_it")
                  : t("dismiss")}
              </button>
              {currentStep < features[currentFeatureIndex].steps.length && (
                <button onClick={handleNext}>{t("next")}</button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureTooltip;
