import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

import useApi from "../../hooks/useApi";

import "./style.scss";

const Profile = () => {
  const [profileData, setProfileData] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { get } = useApi();

  const fetchProfileData = async () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));

    const decodedToken = jwtDecode(ps10005000);

    try {
      const data = await get(`/BusinessAccount/${decodedToken.buid}`);

      setProfileData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  return (
    <div className="profile-container">
      {profileData && (
        <>
          <div className="profile-container-info">
            <div className="profile-contacts">
              <div className="profile-container-name">
                <span>{profileData.name}</span>
              </div>
              <div>
                <span>{t("name")}</span>
              </div>
            </div>
            <div className="profile-contacts">
              <div>
                <span>{profileData.contactMSISDN}</span>
              </div>
              <div>
                <span>{t("mobile")}</span>
              </div>
            </div>
            <div className="profile-contacts">
              <div>{profileData.contactEmail}</div>
              <div>
                <span>{t("email")}</span>
              </div>
            </div>

            <div className="profile-button">
              <button
                onClick={() => {
                  navigate("/sessions");
                }}
              >
                {t("go_main_page")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
