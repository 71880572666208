import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { jwtDecode } from "jwt-decode";

import { ReactComponent as UserIcon } from "../../static/images/user_icon.svg";
import { ReactComponent as QuestionIcon } from "../../static/images/question_sign.svg";

import "./style.scss";
import LangSwitcher from "../LangSwitcher";

const SandwichNavigation = ({ active, sandwichToggle }) => {
  const [log_outToggle, setLog_outToggle] = useState("");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const getUserRole = () => {
    const ps10005000 = JSON.parse(localStorage.getItem("ps10005000"));
    if (ps10005000) {
      const encodedToken = jwtDecode(ps10005000);
      return encodedToken;
    } else {
      return null;
    }
  };

  return (
    <div className={`sandwich-container ${active}`}>
      <div className={`log-out-container ${log_outToggle}`}>
        <div className="log-out">
          <div id="question-icon">
            <QuestionIcon />
          </div>
          <div>
            <h2>{t("logout_confirm")}</h2>
          </div>
          <div className="log-out-confirmation">
            <div
              id="yes"
              onClick={() => {
                localStorage.removeItem("ps10005000");
                localStorage.removeItem("ps10004000");
                sandwichToggle();
                setLog_outToggle("");
                navigate("/");
              }}
            >
              <span>{t("yes")}</span>
            </div>
            <div
              id="no"
              onClick={() => {
                setLog_outToggle("");
              }}
            >
              <span>{t("no")}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="username">
        <UserIcon />
        <span>
          {getUserRole().given_name + " " + getUserRole().family_name}
        </span>
      </div>
      <div className="sandwich-content">
        <ul>
          {getUserRole().roles === "Consumer" ? (
            <>
              <li
                onClick={() => {
                  navigate("/sessions");
                  sandwichToggle();
                }}
              >
                <div>
                  <span>{t("upcomming_sessions")}</span>
                </div>
              </li>
              <li
              // onClick={() => {
              //   navigate("/clubs");
              //   sandwichToggle();
              // }}
              >
                <div style={{ opacity: 0.3 }}>
                  <span>{t("clubs_soon")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  navigate("/invitation");
                  sandwichToggle();
                }}
              >
                <div>
                  <span> {t("calss_invitations")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  navigate("/history");
                  sandwichToggle();
                }}
              >
                <div>
                  <span>{t("training_history")}</span>
                </div>
              </li>

              <li
              // onClick={() => {
              //   navigate("/bills");
              //   sandwichToggle();
              // }}
              >
                <div style={{ opacity: 0.3 }}>
                  <span>{t("bills_soon")}</span>
                </div>
              </li>
            </>
          ) : null}

          {getUserRole().roles === "Provider" ? (
            <>
              <li
                onClick={() => {
                  navigate("/sessions");
                  sandwichToggle();
                }}
              >
                <div>
                  <span>{t("upcomming_sessions")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  navigate("/history/byschedule");
                  sandwichToggle();
                }}
              >
                <div>
                  <span>{t("training_history")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  navigate("/schedules");
                  sandwichToggle();
                }}
              >
                <div>
                  <span>{t("schedules")}</span>
                </div>
              </li>
              <li
              // onClick={() => {
              //   navigate("/customers");
              //   sandwichToggle();
              // }}
              >
                <div style={{ opacity: 0.3 }}>
                  <span>{t("customers_soon")}</span>
                </div>
              </li>
              <li>
                <div style={{ opacity: 0.3 }}>
                  <span>{t("finance_soon")}</span>
                </div>
              </li>
            </>
          ) : null}

          <li>
            <div
              onClick={() => {
                sandwichToggle();
                navigate("/profile");
              }}
            >
              <span>{t("profile")}</span>
            </div>
          </li>

          <button
            className="logout"
            onClick={() => {
              setLog_outToggle("active");
            }}
          >
            {t("logout")}
          </button>
          <LangSwitcher />
        </ul>
      </div>
    </div>
  );
};

function areEqual(preValue, postValue) {
  if (preValue.active !== postValue.active) return false;
  return true;
}

export default React.memo(SandwichNavigation, areEqual);
