import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import PhoneNumberToDial from "../PhoneNumberToDial";

import { ReactComponent as Tennis } from "../../static/images/tennis.svg";
import { ReactComponent as Boxing } from "../../static/images/boxing.svg";
import { ReactComponent as Yoga } from "../../static/images/yoga.svg";
import { ReactComponent as Soccer } from "../../static/images/soccer.svg";
import { ReactComponent as Bascketball } from "../../static/images/basketball.svg";
import { ReactComponent as Dance } from "../../static/images/dance.svg";
import { ReactComponent as PersonalTrainer } from "../../static/images/presonal_trainer.svg";
import { ReactComponent as Other } from "../../static/images/consumer_other.svg";

import "./style.scss";

const ConfirmInvitation = ({
  item,
  setConfirmModalToggle,
  setModalText,
  setSingleInv,
  setInputNameValue,
  setCardAction,
}) => {
  const { t } = useTranslation();

  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThursday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");

  const RenderActivitie = ({ activities }) => {
    const activityIcons = {
      tennis: <Tennis />,
      boxing: <Boxing />,
      yoga: <Yoga />,
      soccer: <Soccer />,
      bascketball: <Bascketball />,
      dance: <Dance />,
      "Personal Trainer": <PersonalTrainer />,
    };

    return <>{activityIcons[activities] || <Other />}</>;
  };

  const checkDetailsDays = (item) => {
    if (item.type !== "OneTime")
      item.scheduleDays.forEach((day) => {
        if (day.dayOfWeek === "Monday") {
          setMonday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Tuesday") {
          setTuesday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Wednesday") {
          setWednesday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Thursday") {
          setThursday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Friday") {
          setFriday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Saturday") {
          setSaturday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
        if (day.dayOfWeek === "Sunday") {
          setSunday(["active", day.timeOnly.slice(0, 5), item.id]);
        }
      });
  };

  useEffect(() => {
    checkDetailsDays(item);
  }, [item]);

  return (
    <li key={item.id} className="invitation-container-li">
      <div className="invitation-list-container">
        <div className="invitation-from">
          <span>{t("invitation_from")}</span>
        </div>
        <div className="invitation-name">
          <div className="activity-icon">
            <RenderActivitie activities={item.activityName} />
          </div>
          <h2>{item.providerName}</h2>
        </div>

        <div className="provider-phone">
          <PhoneNumberToDial number={item.providerPhoneNumber} />
        </div>

        <div className="details-days">
          <div
            className={
              item.scheduleDays[0].dayOfWeek && item.id === monday[2]
                ? monday[0]
                : ""
            }
          >
            <span>{t("monday").slice(0, 3)}</span>
            <span>
              {item.scheduleDays[0].dayOfWeek && item.id === monday[2]
                ? monday[1]
                : ""}
            </span>
          </div>
          <div
            className={
              item.scheduleDays[0].dayOfWeek && item.id === tuesday[2]
                ? tuesday[0]
                : ""
            }
          >
            <span>{t("tuesday").slice(0, 3)}</span>
            <span>
              {" "}
              {item.scheduleDays[0].dayOfWeek && item.id === tuesday[2]
                ? tuesday[1]
                : ""}
            </span>
          </div>
          <div
            className={
              item.scheduleDays[0].dayOfWeek && item.id === wednesday[2]
                ? wednesday[0]
                : ""
            }
          >
            <span>{t("wednesday").slice(0, 3)}</span>
            <span>
              {item.scheduleDays[0].dayOfWeek && item.id === wednesday[2]
                ? wednesday[1]
                : ""}
            </span>
          </div>
          <div
            className={
              item.scheduleDays[0].dayOfWeek && item.id === thursday[2]
                ? thursday[0]
                : ""
            }
          >
            <span>{t("thursday").slice(0, 3)}</span>
            <span>
              {item.scheduleDays[0].dayOfWeek && item.id === thursday[2]
                ? thursday[1]
                : ""}
            </span>
          </div>
          <div
            className={
              item.scheduleDays[0].dayOfWeek && item.id === friday[2]
                ? friday[0]
                : ""
            }
          >
            <span>{t("friday").slice(0, 3)}</span>
            <span>
              {item.scheduleDays[0].dayOfWeek && item.id === friday[2]
                ? friday[1]
                : ""}
            </span>
          </div>
          <div
            className={
              item.scheduleDays[0].dayOfWeek && item.id === saturday[2]
                ? saturday[0]
                : ""
            }
          >
            <span>{t("saturday").slice(0, 3)}</span>
            <span>
              {item.scheduleDays[0].dayOfWeek && item.id === saturday[2]
                ? saturday[1]
                : ""}
            </span>
          </div>
          <div
            className={
              item.scheduleDays[0].dayOfWeek && item.id === sunday[2]
                ? sunday[0]
                : ""
            }
          >
            <span>{t("sunday").slice(0, 3)}</span>
            <span>
              {item.scheduleDays[0].dayOfWeek && item.id === sunday[2]
                ? sunday[1]
                : ""}
            </span>
          </div>
        </div>

        <div className="financial-details">
          <ul>
            <li>
              <span>
                {`${t("price")} ${item?.financialDetails?.pricingType === "Monthly" ? t("per_month") : t("per_session")} ${t("is_price", { price: item?.financialDetails.price })}`}
              </span>
            </li>
            <li>
              <span>
                {`${t("you_will_be_asked_to_pay_full_amount")} ${
                  item?.financialDetails?.billingPeriod ===
                    "Based_On_Session_Events" &&
                  item?.financialDetails?.paymentType === "PostPaid"
                    ? t(
                        "invitation_sessions_billingEventCount",
                        {
                          billingEventCount:
                            item?.financialDetails?.billingEventCount,
                        },
                        {
                          prepaymentEventCount:
                            item?.financialDetails?.prepaymentEventCount,
                        },
                      )
                    : item?.financialDetails?.billingPeriod ===
                          "Based_On_Session_Events" &&
                        item?.financialDetails?.paymentType === "PrePaid"
                      ? t(
                          "of",
                          {
                            billingEventCount:
                              item?.financialDetails?.billingEventCount,
                          },
                          {
                            prepaymentEventCount:
                              item?.financialDetails?.prepaymentEventCount,
                          },
                        )
                      : item?.financialDetails?.billingPeriod ===
                          "End_Of_The_Month"
                        ? t("next_5th_month")
                        : t("5th_month")
                }`}
              </span>
            </li>
          </ul>
        </div>

        <div className="invitation-buttons">
          <div id="invitation-accept">
            <button
              onClick={(e) => {
                setConfirmModalToggle("modal-confirm");
                setModalText(t("activate_subscription"));
                setSingleInv(item);
                setInputNameValue(item.providerName);
                setCardAction("activate");
              }}
            >
              {t("accept")}
            </button>
          </div>
          <div id="invitation-reject">
            <button
              onClick={(e) => {
                setConfirmModalToggle("modal-confirm");
                setModalText(t("cancel_subscription"));
                setSingleInv(item);
                setCardAction("cancel");
              }}
            >
              {t("reject")}
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ConfirmInvitation;
