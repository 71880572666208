import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useStatusModal } from "../../context/StatusModalContext";

const StatusModal = () => {
  const { modalState, closeStatusModal } = useStatusModal();
  const { isError, visible, goToHome, clearStorage, message } = modalState;
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className={isError ? "modal-icon error" : "modal-icon"}>
          <span>{isError ? "❗" : "✓"}</span>
        </div>
        <div className="modal-message">{message}</div>
        <button className="modal-close" onClick={closeStatusModal}>
          {clearStorage
            ? t("click_to_login")
            : goToHome
              ? t("go_home_page")
              : "Close"}
        </button>
      </div>
    </div>
  );
};

export default StatusModal;
