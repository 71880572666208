import React from "react";

import { customersArray } from "../../static/customersArray";

import "./style.css";

const Customers = () => {
  return (
    <div className="customer">
      <div className="may">
        <h3>may</h3>
      </div>
      {customersArray.map((item) => {
        return (
          <div className="single-customer" key={item.day}>
            <div className="day">{item.day}</div>
            <div className="customer-content">
              <div className="lesson">
                <img src={`./${item.icon}.png`} alt="" />
                <div>{item.title}</div>
              </div>
              <div className="clock">{item.clock}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Customers);
