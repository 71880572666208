import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      navigate("/sessions");
      clearTimeout(timeOut);
    }, [2500]);
  }, [navigate]);

  return (
    <>
      <div className="notfound">
        <h1>{t("error_page_not_found")}</h1>
      </div>
    </>
  );
};

export default NotFound;
