import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useRegistration from "../../store/RegistrationStore";

import Registration from "../Registration/Registration";
import AuthInput from "../../Components/AuthInput/AuthInput";
import Button from "../../Components/Button/Button";

import { ReactComponent as Tennis } from "../../static/images/tennis.svg";
import { ReactComponent as Boxing } from "../../static/images/boxing.svg";
import { ReactComponent as PersonalTrainer } from "../../static/images/presonal_trainer.svg";
import { ReactComponent as Yoga } from "../../static/images/yoga.svg";
import { ReactComponent as Soccer } from "../../static/images/soccer.svg";
import { ReactComponent as Bascketball } from "../../static/images/basketball.svg";
import { ReactComponent as Dance } from "../../static/images/dance.svg";

import "./style.scss";

const ProviderRegistration = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [addressValidation, setAddresValidation] = useState("");
  const [otherActNameToggle, setOtherActNameToggle] = useState("");

  const {
    city,
    district,
    address,
    activityName,
    providerRegistrationSteps,

    changeCity,
    changeDistrict,
    changeAddress,
    changeActivityName,
    changeProviderRegistrationSteps,
  } = useRegistration();

  const submitAddress = (e) => {
    e.preventDefault();
    if (
      city.trim() === "" ||
      district.trim() === "" ||
      address.trim() === "" ||
      activityName === ""
    ) {
      setAddresValidation(t("error_fill_all"));
    } else {
      setAddresValidation("");
      changeProviderRegistrationSteps(2);
    }
  };

  useEffect(() => {
    localStorage.removeItem("ps10005000");
    localStorage.removeItem("ps10004000");
  }, []);

  useEffect(() => {
    if (providerRegistrationSteps > 0) {
      window.addEventListener("beforeunload", (event) => {
        event.preventDefault();
      });
    }
  }, [providerRegistrationSteps]);

  return (
    <>
      {providerRegistrationSteps === 0 ? (
        <div className="provider-registraton-category">
          <div className="provider-registraton-header">
            <h1>{t("sign_up")}</h1>
          </div>
          <div className="provider-registraton-subtext">
            <h3>{t("provider_regitration_subtext")}</h3>
          </div>
          <div className="provider-registraton-categories">
            <ul>
              <li
                onClick={() => {
                  changeActivityName("tennis");
                  changeProviderRegistrationSteps(1);
                }}
              >
                <div className="category-image">
                  <Tennis />
                </div>
                <div className="caregory-name">
                  <span>{t("provider_regitration_category_tennis")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  changeActivityName("boxing");
                  changeProviderRegistrationSteps(1);
                }}
              >
                <div className="category-image">
                  <Boxing />
                </div>
                <div className="caregory-name">
                  <span>{t("provider_regitration_category_boxing")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  changeActivityName("Personal Trainer");
                  changeProviderRegistrationSteps(1);
                }}
              >
                <div className="category-image">
                  <PersonalTrainer />
                </div>
                <div className="caregory-name">
                  <span>
                    {t("provider_regitration_category_presonal_trainer")}
                  </span>
                </div>
              </li>
              <li
                onClick={() => {
                  changeActivityName("yoga");
                  changeProviderRegistrationSteps(1);
                }}
              >
                <div className="category-image">
                  <Yoga />
                </div>
                <div className="caregory-name">
                  <span>{t("provider_regitration_category_yoga")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  changeActivityName("soccer");
                  changeProviderRegistrationSteps(1);
                }}
              >
                <div className="category-image">
                  <Soccer />
                </div>
                <div className="caregory-name">
                  <span>{t("provider_regitration_category_soccer")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  changeActivityName("bascketball");
                  changeProviderRegistrationSteps(1);
                }}
              >
                <div className="category-image">
                  <Bascketball />
                </div>
                <div className="caregory-name">
                  <span>{t("provider_regitration_category_bascketball")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  changeActivityName("dance");
                  changeProviderRegistrationSteps(1);
                }}
              >
                <div className="category-image">
                  <Dance />
                </div>
                <div className="caregory-name">
                  <span>{t("provider_regitration_category_dance")}</span>
                </div>
              </li>
              <li
                onClick={() => {
                  setOtherActNameToggle("other");
                  changeProviderRegistrationSteps(1);
                }}
              >
                <div className="caregory-name">
                  <span>{t("provider_regitration_category_other")}</span>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="provider-registraton-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>{t("go_previous")}</span>
          </div>
        </div>
      ) : null}

      {providerRegistrationSteps === 1 ? (
        <div className="provider-registraton-adderss">
          <div className="provider-registraton-header">
            <h1>{t("sign_up")}</h1>
          </div>
          <div className="provider-registraton-subtext">
            <h3>
              {otherActNameToggle === "other"
                ? t("provider_regitration_subheader1")
                : t("provider_regitration_subheader1")}
            </h3>
          </div>
          <div className="provider-address-form">
            <form
              onSubmit={(e) => {
                submitAddress(e);
              }}
            >
              {otherActNameToggle === "other" ? (
                <AuthInput
                  name={"activityName"}
                  type={"text"}
                  placeholder={t("input_label_activity_type")}
                  value={activityName}
                  changeValue={changeActivityName}
                />
              ) : null}

              <AuthInput
                name={"city"}
                type={"text"}
                placeholder={t("input_label_city")}
                value={city}
                changeValue={changeCity}
              />
              <AuthInput
                name={"district"}
                type={"text"}
                placeholder={t("input_label_district")}
                value={district}
                changeValue={changeDistrict}
              />
              <AuthInput
                name={"address"}
                type={"text"}
                placeholder={t("input_label_address")}
                value={address}
                changeValue={changeAddress}
              />

              <Button text={t("next")} type={"submit"} />
              <Button
                text={t("back")}
                type={"button"}
                clickEvent={() => {
                  changeProviderRegistrationSteps(0);
                  setAddresValidation("");
                  setOtherActNameToggle("");
                }}
              />
            </form>
          </div>

          <div className="adddress-validation">
            <h3>{addressValidation}</h3>
          </div>

          {/* <div
            className="provider-registraton-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>Back to previous page</span>
          </div> */}
        </div>
      ) : null}

      {providerRegistrationSteps === 2 ? <Registration /> : null}
    </>
  );
};

export default ProviderRegistration;
