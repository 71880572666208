import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useApi from "../../hooks/useApi";

import Weekdays from "../../Components/Weekdays/Weekdays";

import { ReactComponent as Single } from "../../static/images/single_schedule.svg";
import { ReactComponent as Group } from "../../static/images/group_schedule.svg";
import { ReactComponent as Passed } from "../../static/images/passed_history.svg";
import { ReactComponent as Canceled } from "../../static/images/canceled_history.svg";

import "./style.scss";

const ProviderByscheduleHistoryDetails = () => {
  const { get, loading } = useApi();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const { scheduleId, startEventDate, endEventDate, userId, month } =
    location.state || {};

  const [scheduleData, setScheduleData] = useState([]);
  const [cardsData, setcardsData] = useState([]);

  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  const weekdays = [
    t("sunday"),
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
  ];

  function getMonthByIndex(index) {
    if (index >= 0 && index <= 11) {
      return months[index];
    }
  }

  const fetchSchedule = async () => {
    try {
      const data = await get(`/Schedule/${scheduleId}`);

      setScheduleData(data);
    } catch (err) {}
  };

  const fetchCardData = async () => {
    try {
      const data = await get(
        `/ApplicationEvent?ScheduleId=${scheduleId}&EventDate=${startEventDate}&EndEventDate=${endEventDate}&EventStatuses=Cancelled&EventStatuses=Passed&ProviderBusinessAccountId=${userId}`,
      );

      setcardsData(data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchCardData();
    fetchSchedule();
  }, []);

  return (
    <div className="provider-byschedule-details">
      <div className="description">
        <div className="description-icon">
          {scheduleData.groupSchedule === true ? <Group /> : <Single />}
        </div>
        <span>{scheduleData.description}</span>
      </div>

      {scheduleData?.scheduleDays && (
        <Weekdays weekdaysData={scheduleData.scheduleDays} />
      )}

      <div className="section-devider"></div>
      <div className="event-date">
        <h2>
          {getMonthByIndex(new Date(startEventDate).getMonth()) +
            " " +
            new Date(startEventDate).getFullYear()}
        </h2>
      </div>

      <div className="section-devider"></div>

      <div className="details-card-container">
        <ul>
          {cardsData &&
            cardsData.map((item) => {
              return (
                <li key={item.id} className="details-card-li">
                  <div className="details-card">
                    <div className="card-item">
                      <div className="card-item-content">
                        <div className="card-status">
                          {item.eventStatus === "Passed" ? (
                            <Passed />
                          ) : (
                            <Canceled />
                          )}
                        </div>
                        <div className="card-time">
                          <span>
                            {" "}
                            {new Date(item.eventDate)
                              .getHours()
                              .toString()
                              .padStart(2, "0") +
                              ":" +
                              new Date(item.eventDate)
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}
                          </span>
                        </div>

                        <div className="card-weekday">
                          <span>
                            {" "}
                            {weekdays[new Date(item.eventDate).getDay()].slice(
                              0,
                              3,
                            )}
                          </span>
                        </div>

                        <div className="card-date">
                          <span>
                            {" "}
                            {new Date(item.eventDate).getDate() +
                              " " +
                              getMonthByIndex(
                                new Date(startEventDate).getMonth(),
                              ).slice(0, 3)}
                          </span>
                        </div>
                      </div>

                      <div className="attenders">
                        <button
                          onClick={() => {
                            navigate(item.id, {
                              state: {
                                subscriberId: item.id,
                                eventDate: item.eventDate,
                                description: scheduleData.description,
                                groupStatus: scheduleData.groupSchedule,
                                weekday: new Date(item.eventDate).getDay(),
                              },
                            });
                          }}
                        >
                          {t("show_attenders")}
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div
        className="back-btn"
        onClick={() => {
          navigate(-1);
        }}
      >
        <span>{t("go_previous")}</span>
      </div>

      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default ProviderByscheduleHistoryDetails;
