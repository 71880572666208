import Layout from "./Layout";
import AddToHome from "./Components/AddToHome/AddToHome";

import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Layout />
      <AddToHome />
    </div>
  );
};

export default App;
